import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function MissionVision() {
  const { t } = useTranslation("missionVision");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Mission Vision`}</title>
        <meta
          name="description"
          content={`Our mission is to encourage cooperation between stakeholders in a challenging competitive environment and to offer the most appropriate solutions to both businesses and customers at the most affordable cost by combining them with the latest technologies. Our vision is to contribute to global competitiveness and to be a pioneer that develops the \"Firsts\" and the \"Mosts\" by taking businesses one step beyond technology.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("missionVision")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("missionVision")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      {/* aboutus-section */}
      <section className="ttm-row aboutus-section-style3 clearfix">
        <div className="container-xl">
          <div className="row">
            {/* row */}
            <div className="col-lg-6">
              <div className="position-relative">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                  <img
                    className="img-fluid"
                    src="../images/missionVision.png"
                    title="Mission - Vision"
                    alt="Mission - Vision"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="res-991-mt-30">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>{t("missionVision")}</h5>
                  </div>
                  <div className="title-desc">
                    <p>{t("missionVisionText")}</p>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* aboutus-section end */}
      {/* zero-padding-section */}
      {/* zero-padding-section end */}
      {/* team-section */}
      {/* team-section end*/}
      {/* our-partner-section */}
      {/* our-partner-section end */}
      {/* blog-section */}
      {/* blog-section end */}
    </div>
  );
}
