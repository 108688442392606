import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function WhyUs() {
  const { t } = useTranslation("whyUs");
  return (
    <div className="site-main">
      {" "}
      <Helmet>
        <title>{`Runic Bytes - Web / Why Us`}</title>
        <meta
          name="description"
          content={`We have highly skilled engineers with excellent technical knowledge and experience using the latest software standards, tools, platforms, frameworks and technologies, and we continually invest in education and training to be able to respond to any new technology challenges and demands from our customers.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("whyUs")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("whyUs")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      {/* aboutus-section */}
      <section className="ttm-row aboutus-section clearfix">
        <div className="container-xl">
          <div className="row">
            {/* row */}
            <div className="col-lg-12">
              {/* section title */}
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h5>{t("whyUs")}</h5>
                  <h2 className="title">{t("motto")}</h2>
                </div>
                <div className="title-desc">
                  <p>{t("whyUsText1")}</p>
                  <p>{t("whyUsText2")}</p>
                  <p>{t("whyUsText3")}</p>
                  <p>{t("whyUsText4")}</p>
                  <p>{t("whyUsText5")}</p>
                  <p>{t("whyUsText6")}</p>
                </div>
              </div>
              {/* section title end */}
              {/* row */}
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* aboutus-section end*/}
      {/* progress-bar-section */}
    </div>
  );
}
