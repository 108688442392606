import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer(props) {
  const { t } = useTranslation(["footer"]);
  return (
    <footer className="footer widget-footer clearfix">
      {props.children}
      <div className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes bg-img1">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container-xl">
          <div className="row align-items-md-center">
            <div className="col-lg-4 col-md-4 col-sm-12 order-md-2">
              <div className="footer-logo text-sm-center">
                <img
                  data-src="images/footer-logo.png"
                  className="img-fluid"
                  alt="Runic Bytes"
                  src="images/footer-logo.png"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 order-md-1 res-767-mt-20">
              <div className="text-left">
                {/*  featured-icon-box */}
                <div className="featured-icon-box left-icon icon-align-top">
                  <div className="featured-icon">
                    {/*  featured-icon */}
                    <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                      <i className="ti ti-location-pin" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-desc">
                      <p>{t("officeAddress")}</p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 order-md-3 res-767-mt-20">
              <div className="text-sm-right">
                <a
                  className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"
                  style={{ textTransform: "lowercase" }}
                  href="mailto:info@runicbytes.com"
                >
                  <i className="far fa-envelope" /> info@runicbytes.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="second-footer ttm-textcolor-white bg-img2">
        <div className="container-xl">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
              <div className="widget widget_text  clearfix">
                <h3 className="widget-title">{t("aboutus")}</h3>
                <div className="textwidget widget-text">{t("companyInfo")}</div>
                <div className="quicklink-box">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                        <span className="flaticon flaticon-clock" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                    <div className="featured-content">
                      {/*  featured-content */}
                      <div className="featured-desc">
                        {/*  featured-desc */}
                        <p>{t("contactSupport")}</p>
                      </div>
                      <div className="featured-title">
                        {/*  featured-title */}
                        <h5>{t("contactPhone")}</h5>
                      </div>
                    </div>
                  </div>
                  {/*  featured-icon-box END */}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
              <div className="widget link-widget clearfix">
                <h3 className="widget-title">{t("quickLinks")}</h3>
                <ul id="menu-footer-services">
                  <li>
                    <a href={process.env.PUBLIC_URL + "/appointment"}>
                      {t("makeAppointment")}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/companyInfo"}>
                      {t("companyInfo2")}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/ourWayOfWorking"}>
                      {t("ourWayOfWorking")}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/freeConsulting"}>
                      {t("freeConsulting")}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/quotation"}>
                      {t("quotation")}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/ourServices"}>
                      {t("ourServices")}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/technologies/all"}>
                      {t("technologies")}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/packageList"}>
                      {t("packageList")}
                    </a>
                  </li>
                  <li>
                    <a
                      href={process.env.PUBLIC_URL + "/grantSupportConsultancy"}
                    >
                      {t("grantSupportConsultancy")}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/expertiseServices"}>
                      {t("expertiseServices")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area res-767-mb-40 mt-auto">
              <div className="widget flicker_widget clearfix">
                <a href="https://www.dtr-ihk.de/tr/">
                  <img
                    height={100}
                    src="../images/ahk-member-logo.png"
                    title="Deutsch-Türkische Industrie- und Handelskammer"
                    alt="Deutsch-Türkische Industrie- und Handelskammer"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
        <div className="container-xl">
          <div className="row copyright align-items-center res-767-text-center">
            <div className="col-lg-6 col-md-4">
              <div>
                <span>
                  Copyright © 2022 - {new Date().getFullYear()}&nbsp;
                  <a href="/">Runic Bytes</a>
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-md-8">
              <div className="text-md-right res-767-mt-10">
                <div className="d-lg-inline-flex">
                  <ul id="menu-footer-menu" className="footer-nav-menu">
                    <li>
                      <a href={process.env.PUBLIC_URL + "/aboutUs"}>
                        {t("aboutus")}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/ourServices"}>
                        {t("ourServices")}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/privacy"}>
                        {t("privacy")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
