import React, { useState } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBHamburgerToggler,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Mobilemenu() {
  const [collapseId, toggleSingleCollapse] = useState(false);
  const { t } = useTranslation("menu");

  return (
    <Router>
      <MDBNavbar>
        <MDBHamburgerToggler
          id="hamburger1"
          onClick={() => toggleSingleCollapse(!collapseId)}
        />
        <MDBCollapse isOpen={collapseId} navbar>
          <MDBNavbarNav left>
            <MDBDropdownItem
              href={process.env.PUBLIC_URL + "/"}
              className="nav-link"
            >
              {t("homepage")}
            </MDBDropdownItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret className="flaticon">
                  <span>{t("aboutus")}</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem href={process.env.PUBLIC_URL + "/aboutUs"}>
                    {t("aboutus")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/companyInfo"}
                  >
                    {t("companyInfo")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/missionVision"}
                  >
                    {t("missionVision")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/ourPolicies"}
                  >
                    {t("ourPolicies")}
                  </MDBDropdownItem>
                  <MDBDropdownItem href={process.env.PUBLIC_URL + "/ourValues"}>
                    {t("ourValues")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/ourWayOfWorking"}
                  >
                    {t("ourWayOfWorking")}
                  </MDBDropdownItem>
                  <MDBDropdownItem href={process.env.PUBLIC_URL + "/whyUs"}>
                    {t("whyUs")}
                  </MDBDropdownItem>
                  <MDBDropdownItem href={process.env.PUBLIC_URL + "/contracts"}>
                    {t("contracts")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/packageList"}
                  >
                    {t("packageList")}
                  </MDBDropdownItem>
                  <MDBDropdownItem href={process.env.PUBLIC_URL + "/faq"}>
                    {t("faq")}
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span>{t("ourServices")}</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/ourServices"}
                  >
                    {t("ourServices")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/softwareConsultancy"}
                  >
                    {t("softwareConsultancy")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/webDesktopDevelopment"}
                  >
                    {t("webDesktopDevelopment")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/mobileDevelopment"}
                  >
                    {t("mobileDevelopment")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/uiUxDesign"}
                  >
                    {t("uiUxDesign")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/devOpsSecOpsSupport"}
                  >
                    {t("devOpsSecOpsSupport")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={
                      process.env.PUBLIC_URL + "/aiMachineLearningSolutions"
                    }
                  >
                    {t("aiMachineLearningSolutions")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/blockChainSolutions"}
                  >
                    {t("blockChainSolutions")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/noSqlRelationalSolutions"}
                  >
                    {t("noSqlRelationalSolutions")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/technicalSupport"}
                  >
                    {t("technicalSupport")}
                  </MDBDropdownItem>
                  <MDBDropdownItem href={process.env.PUBLIC_URL + "/baSupport"}>
                    {t("baSupport")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/testSupport"}
                  >
                    {t("testSupport")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/eTransformation"}
                  >
                    {t("eTransformation")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/agileTransformation"}
                  >
                    {t("agileTransformation")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/grantSupportConsultancy"}
                  >
                    {t("grantSupportConsultancy")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/expertiseServices"}
                  >
                    {t("expertiseServices")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/freeConsulting"}
                  >
                    {t("freeConsulting")}
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span>{t("technologies")}</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/technologies/all"}
                  >
                    {t("technologies")}
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span>{t("ourProducts")}</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/ourProducts/all"}
                  >
                    {t("ourProducts")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/smartBytes"}
                  >
                    Smart Bytes
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span>{t("contact")}</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem href={process.env.PUBLIC_URL + "/contact"}>
                    {t("contact")}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/appointment"}
                  >
                    {t("appointment")}
                  </MDBDropdownItem>
                  <MDBDropdownItem href={process.env.PUBLIC_URL + "/quotation"}>
                    {t("quotation")}
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    </Router>
  );
}
