import _ from "../helpers/@loadash";
import { withFormsy } from "formsy-react";
import React from "react";
import { useTranslation } from "react-i18next";

function TextArea(props) {
  const { t } = useTranslation("formsy");
  const importedProps = _.pick(props, [
    "className",
    "placeholder",
    "name",
    "rows",
    "required",
  ]);

  const { errorMessage, showRequired } = props;
  const value = props.value || "";
  let error = errorMessage;
  if (!error && showRequired) {
    error = t("required");
  }

  function changeValue(event) {
    props.setValue(event.currentTarget.value);
    if (props.onChange) {
      props.onChange(event.currentTarget.value);
    }
  }

  return (
    <div className={importedProps.className || "col-sm-12 col-md-12"}>
      <div className="form-group">
        <textarea
          name={importedProps.name}
          rows={importedProps.rows || 5}
          placeholder={importedProps.placeholder}
          required={importedProps.required}
          value={value}
          className="form-control bg-white border"
          onChange={changeValue}
        />
        <small>{error}</small>
      </div>
    </div>
  );
}

export default React.memo(withFormsy(TextArea));
