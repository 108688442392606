import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Layout
import Footer from "./components/layout/Footer";
// pages
import Home from "./components/Home";
import Contact from "./components/pages/Contact";
import Appointment from "./components/pages/Appointment";
import Error from "./components/pages/Error";
import ScrollToTop from "./components/layout/Gotop";
import CompanyInfo from "./components/pages/CompanyInfo";
import MissionVision from "./components/pages/MissionVision";
import OurPolicies from "./components/pages/OurPolicies";
import OurValues from "./components/pages/OurValues";
import WhyUs from "./components/pages/WhyUs";
import SoftwareConsultancy from "./components/pages/SoftwareConsultancy";
import WebDesktopDevelopment from "./components/pages/WebDesktopDevelopment";
import MobileDevelopment from "./components/pages/MobileDevelopment";
import UiUxDesign from "./components/pages/UiUxDesign";
import DevOpsSecOpsSupport from "./components/pages/DevOpsSecOpsSupport";
import AiMachineLearningSolutions from "./components/pages/AiMachineLearningSolutions";
import BlockChainSolutions from "./components/pages/BlockChainSolutions";
import NoSqlRelationalSolutions from "./components/pages/NoSqlRelationalSolutions";
import TechnicalSupport from "./components/pages/TechnicalSupport";
import BaSupport from "./components/pages/BaSupport";
import TestSupport from "./components/pages/TestSupport";
import ETransformation from "./components/pages/ETransformation";
import AgileTransformation from "./components/pages/AgileTransformation";
import GrantSupportConsultancy from "./components/pages/GrantSupportConsultancy";
import ExpertiseServices from "./components/pages/ExpertiseServices";
import SmartBytes from "./components/pages/SmartBytes";
import OurWayOfWorking from "./components/pages/OurWayOfWorking";
import FreeConsulting from "./components/pages/FreeConsulting";
import Technologies from "./components/pages/Technologies";
import Privacy from "./components/pages/Privacy";
import Contracts from "./components/pages/Contracts";
import PackageList from "./components/pages/PackageList";
import AboutUs from "./components/pages/AboutUs";
import OurServices from "./components/pages/OurServices";
import OurProducts from "./components/pages/OurProducts";
import Quotation from "./components/pages/Quotation";
import Faq from "./components/pages/Faq";
import BusyIndicator from "./components/layout/BusyIndicator";
import Modal from "./components/layout/Modal";
import { Helmet } from "react-helmet";

const appHistory = {
  basename: process.env.PUBLIC_URL,
};
const history = createBrowserHistory(appHistory);
function App() {
  return (
    <div className="page">
      <Helmet>
        <title>{`Runic Bytes – Software Solutions and Services`}</title>
        <meta
          name="description"
          content="Founded in 2022, Runic Bytes is the partner of choice for many of the world's leading businesses, SMEs and technology challengers. It helps businesses elevate their value through offshore and custom software development, project management, analysis, testing, user interface design, product design, quality assurance and consulting services."
        />
      </Helmet>
      <Router basename={process.env.PUBLIC_URL} history={history}>
        <div id="preloader">
          <div id="status">&nbsp;</div>
        </div>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/companyInfo" component={CompanyInfo} />
          <Route path="/missionVision" component={MissionVision} />
          <Route path="/ourPolicies" component={OurPolicies} />
          <Route path="/ourValues" component={OurValues} />
          <Route path="/whyUs" component={WhyUs} />
          <Route path="/softwareConsultancy" component={SoftwareConsultancy} />
          <Route
            path="/webDesktopDevelopment"
            component={WebDesktopDevelopment}
          />
          <Route path="/mobileDevelopment" component={MobileDevelopment} />
          <Route path="/uiUxDesign" component={UiUxDesign} />
          <Route path="/devOpsSecOpsSupport" component={DevOpsSecOpsSupport} />
          <Route
            path="/aiMachineLearningSolutions"
            component={AiMachineLearningSolutions}
          />
          <Route path="/blockChainSolutions" component={BlockChainSolutions} />
          <Route
            path="/noSqlRelationalSolutions"
            component={NoSqlRelationalSolutions}
          />
          <Route path="/technicalSupport" component={TechnicalSupport} />
          <Route path="/baSupport" component={BaSupport} />
          <Route path="/testSupport" component={TestSupport} />
          <Route path="/eTransformation" component={ETransformation} />
          <Route path="/agileTransformation" component={AgileTransformation} />
          <Route
            path="/grantSupportConsultancy"
            component={GrantSupportConsultancy}
          />
          <Route path="/expertiseServices" component={ExpertiseServices} />
          <Route path="/smartBytes" component={SmartBytes} />
          <Route path="/ourWayOfWorking" component={OurWayOfWorking} />
          <Route path="/freeConsulting" component={FreeConsulting} />
          <Route path="/technologies/:selectedTabId" component={Technologies} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/contracts" component={Contracts} />
          <Route path="/packageList" component={PackageList} />
          <Route path="/contact" component={Contact} />
          <Route path="/appointment" component={Appointment} />
          <Route path="/aboutUs" component={AboutUs} />
          <Route path="/ourServices" component={OurServices} />
          <Route path="/ourProducts/:selectedTabId" component={OurProducts} />
          <Route path="/quotation" component={Quotation} />
          <Route path="/faq" component={Faq} />
          <Route path="/404" component={Error} />
          <Redirect to="/404" />
        </Switch>
        <Modal />
        <Footer />
        <BusyIndicator />
      </Router>
    </div>
  );
}

export default App;
