import React, { useState } from "react";
import Header from "../layout/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useTranslation } from "react-i18next";
import TechnologyTag from "./TechnologyTag";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Technologies() {
  const { t } = useTranslation("technologies");
  const { selectedTabId } = useParams();
  const history = useHistory();
  let selectedTabIndex = 0;
  switch (selectedTabId) {
    case "all":
      selectedTabIndex = 0;
      break;
    case "webDevelopment":
      selectedTabIndex = 1;
      break;
    case "desktopDevelopment":
      selectedTabIndex = 2;
      break;
    case "mobileDevelopment":
      selectedTabIndex = 3;
      break;
    case "uiUxDesign":
      selectedTabIndex = 4;
      break;
    case "devOpsSecOps":
      selectedTabIndex = 5;
      break;
    case "aiMachineLearning":
      selectedTabIndex = 6;
      break;
    case "blockchain":
      selectedTabIndex = 7;
      break;
    case "database":
      selectedTabIndex = 8;
      break;
    case "businessAnalysis":
      selectedTabIndex = 9;
      break;
    case "testing":
      selectedTabIndex = 10;
      break;
    case "cloud":
      selectedTabIndex = 11;
      break;
    default:
      history.push("/404");
  }

  const getUrl = (index) => {
    switch (index) {
      case 0:
        return "all";
      case 1:
        return "webDevelopment";
      case 2:
        return "desktopDevelopment";
      case 3:
        return "mobileDevelopment";
      case 4:
        return "uiUxDesign";
      case 5:
        return "devOpsSecOps";
      case 6:
        return "aiMachineLearning";
      case 7:
        return "blockchain";
      case 8:
        return "database";
      case 9:
        return "businessAnalysis";
      case 10:
        return "testing";
      case 11:
        return "cloud";
      default:
        return "404";
    }
  };

  const [tabIndex, setTabIndex] = useState(selectedTabIndex | 0);
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Technologies`}</title>
        <meta name="description" content={`Technologies and Tools`} />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("technologies")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("technologies")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="ttm-row tab-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h1 className="title">{t("title")}</h1>
                </div>
              </div>
              <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => {
                    setTabIndex(index);
                    history.push("/technologies/" + getUrl(index));
                  }}
                >
                  <TabList className="text-uppercase mb-4">
                    <Tab>
                      <span>{t("tab1")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab2")} </span>
                    </Tab>
                    <Tab>
                      <span>{t("tab3")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab4")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab5")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab6")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab7")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab8")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab9")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab10")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab11")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab12")}</span>
                    </Tab>
                  </TabList>
                  <div className="content-tab">
                    <TabPanel>
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <TechnologyTag
                          image="net.png"
                          title={`${t("tab2")} / ${t("tab3")} / ${t("tab8")} `}
                          subtitle="Back End / Front End"
                          href=".net"
                          text="Microsoft .NET"
                        />
                        <TechnologyTag
                          image="java.png"
                          title={`${t("tab2")} / ${t("tab3")} / ${t(
                            "tab4"
                          )} / ${t("tab8")} `}
                          subtitle="Back End / Front End"
                          href="java"
                          text="Java"
                        />
                        <TechnologyTag
                          image="python.jpg"
                          title={`${t("tab2")} / ${t("tab3")} / ${t(
                            "tab7"
                          )} / ${t("tab8")} `}
                          subtitle="Back End / Front End"
                          href="python"
                          text="Python"
                        />
                        <TechnologyTag
                          image="node.png"
                          title={`${t("tab2")} / ${t("tab8")} `}
                          subtitle="Back End"
                          href="node"
                          text="Node.js"
                        />
                        <TechnologyTag
                          image="cpp.png"
                          title={`${t("tab2")} / ${t("tab3")} / ${t("tab8")} `}
                          subtitle="Back End"
                          href="cpp"
                          text="C++"
                        />
                        <TechnologyTag
                          image="go.png"
                          title={`${t("tab2")} / ${t("tab8")} `}
                          subtitle="Back End"
                          href="golang"
                          text="Golang"
                        />
                        <TechnologyTag
                          image="php.png"
                          title={`${t("tab2")}`}
                          subtitle="Back End / Front End"
                          href="php"
                          text="PHP"
                        />
                        <TechnologyTag
                          image="html.png"
                          title={`${t("tab2")}`}
                          subtitle="Front End"
                          href="html"
                          text="HTML"
                        />
                        <TechnologyTag
                          image="css.png"
                          title={`${t("tab2")}`}
                          subtitle="Front End"
                          href="css"
                          text="CSS"
                        />
                        <TechnologyTag
                          image="js.png"
                          title={`${t("tab2")} / ${t("tab8")} `}
                          subtitle="Back End / Front End"
                          href="javascript"
                          text="Javascript"
                        />
                        <TechnologyTag
                          image="bootstrap.png"
                          title={`${t("tab2")}`}
                          subtitle="Front End"
                          href="bootstrap"
                          text="Bootstrap"
                        />
                        <TechnologyTag
                          image="angular.png"
                          title={`${t("tab2")}`}
                          subtitle="Front End"
                          href="angular"
                          text="Angular"
                        />
                        <TechnologyTag
                          image="react.png"
                          title={`${t("tab2")}`}
                          subtitle="Front End"
                          href="react"
                          text="React"
                        />
                        <TechnologyTag
                          image="vue.png"
                          title={`${t("tab2")}`}
                          subtitle="Front End"
                          href="vue"
                          text="Vue"
                        />
                        <TechnologyTag
                          image="backbone.png"
                          title={`${t("tab2")}`}
                          subtitle="Front End"
                          href="backbone"
                          text="Backbone"
                        />
                        <TechnologyTag
                          image="knockout.png"
                          title={`${t("tab2")}`}
                          subtitle="Front End"
                          href="knockout"
                          text="Knockout"
                        />
                        <TechnologyTag
                          image="ember.png"
                          title={`${t("tab2")}`}
                          subtitle="Front End"
                          href="ember"
                          text="Ember"
                        />
                        <TechnologyTag
                          image="qt.png"
                          title={`${t("tab3")}`}
                          subtitle=""
                          href="qt"
                          text="Qt"
                        />
                        <TechnologyTag
                          image="electron.png"
                          title={`${t("tab3")}`}
                          subtitle=""
                          href="electron"
                          text="Electron"
                        />
                        <TechnologyTag
                          image="csharp.png"
                          title={`${t("tab3")} / ${t("tab8")}`}
                          subtitle=""
                          href="csharp"
                          text="C#"
                        />
                        <TechnologyTag
                          image="ios.jpg"
                          title={`${t("tab4")}`}
                          subtitle=""
                          href="ios"
                          text="iOS"
                        />
                        <TechnologyTag
                          image="android.jpg"
                          title={`${t("tab4")}`}
                          subtitle=""
                          href="android"
                          text="Android"
                        />
                        <TechnologyTag
                          image="react-native.png"
                          title={`${t("tab4")}`}
                          subtitle=""
                          href="react-native"
                          text="React Native"
                        />
                        <TechnologyTag
                          image="flutter.jpeg"
                          title={`${t("tab4")}`}
                          subtitle=""
                          href="flutter"
                          text="Flutter"
                        />
                        <TechnologyTag
                          image="balsamiq.png"
                          title={`${t("tab5")} / ${t("tab10")}`}
                          subtitle=""
                          href="balsamiq"
                          text="Balsamiq Mockup"
                        />
                        <TechnologyTag
                          image="drawio.png"
                          title={`${t("tab5")} / ${t("tab10")}`}
                          subtitle=""
                          href="drawio"
                          text="Draw.io"
                        />
                        <TechnologyTag
                          image="omnigraffle.png"
                          title={`${t("tab5")}`}
                          subtitle=""
                          href="omnigraffle"
                          text="OmniGraffle"
                        />
                        <TechnologyTag
                          image="sketch.png"
                          title={`${t("tab4")}`}
                          subtitle=""
                          href="sketch"
                          text="Sketch"
                        />
                        <TechnologyTag
                          image="xd.png"
                          title={`${t("tab4")}`}
                          subtitle=""
                          href="xd"
                          text="Adobe XD"
                        />
                        <TechnologyTag
                          image="docker.jpg"
                          title={`${t("tab6")}`}
                          subtitle=""
                          href="docker"
                          text="Docker"
                        />
                        <TechnologyTag
                          image="kubernetes.png"
                          title={`${t("tab6")}`}
                          subtitle=""
                          href="kubernetes"
                          text="Kubernetes"
                        />
                        <TechnologyTag
                          image="prometheus.png"
                          title={`${t("tab6")}`}
                          subtitle=""
                          href="prometheus"
                          text="Prometheus"
                        />
                        <TechnologyTag
                          image="grafana.png"
                          title={`${t("tab6")}`}
                          subtitle=""
                          href="grafana"
                          text="Grafana"
                        />
                        <TechnologyTag
                          image="elk.png"
                          title={`${t("tab6")}`}
                          subtitle=""
                          href="elk"
                          text="ELK Stack"
                        />
                        <TechnologyTag
                          image="teamcity.png"
                          title={`${t("tab6")}`}
                          subtitle=""
                          href="teamcity"
                          text="TeamCity"
                        />
                        <TechnologyTag
                          image="jenkins.png"
                          title={`${t("tab6")}`}
                          subtitle=""
                          href="jenkins"
                          text="Jenkins"
                        />
                        <TechnologyTag
                          image="bamboo.png"
                          title={`${t("tab6")}`}
                          subtitle=""
                          href="bamboo"
                          text="Bamboo"
                        />
                        <TechnologyTag
                          image="azure-devops.jpg"
                          title={`${t("tab6")} / ${t("tab10")} / ${t("tab11")}`}
                          subtitle=""
                          href="azure-devops"
                          text="Azure DevOps"
                        />
                        <TechnologyTag
                          image="gitlab-cicd.png"
                          title={`${t("tab6")}`}
                          subtitle=""
                          href="gitlab-cicd"
                          text="Gitlab CI CD"
                        />
                        <TechnologyTag
                          image="ansible.jpg"
                          title={`${t("tab6")}`}
                          subtitle=""
                          href="ansible"
                          text="Ansible"
                        />
                        <TechnologyTag
                          image="matlab.jpg"
                          title={`${t("tab7")}`}
                          subtitle=""
                          href="matlab"
                          text="MATLAB"
                        />
                        <TechnologyTag
                          image="r.png"
                          title={`${t("tab7")}`}
                          subtitle=""
                          href="r"
                          text="R"
                        />
                        <TechnologyTag
                          image="pytorch.png"
                          title={`${t("tab7")}`}
                          subtitle=""
                          href="pytorch"
                          text="PyTorch"
                        />
                        <TechnologyTag
                          image="tenserflow.png"
                          title={`${t("tab7")}`}
                          subtitle=""
                          href="tenserflow"
                          text="TenserFlow"
                        />
                        <TechnologyTag
                          image="keras.png"
                          title={`${t("tab7")}`}
                          subtitle=""
                          href="keras"
                          text="Keras"
                        />
                        <TechnologyTag
                          image="torch.png"
                          title={`${t("tab7")}`}
                          subtitle=""
                          href="torch"
                          text="Torch"
                        />
                        <TechnologyTag
                          image="mahout.png"
                          title={`${t("tab7")}`}
                          subtitle=""
                          href="mahout"
                          text="Apache Mahout"
                        />
                        <TechnologyTag
                          image="opencv.png"
                          title={`${t("tab7")}`}
                          subtitle=""
                          href="opencv"
                          text="OpenCV"
                        />
                        <TechnologyTag
                          image="hyperledger.png"
                          title={`${t("tab8")}`}
                          subtitle=""
                          href="hyperledger"
                          text="Hyperledger"
                        />
                        <TechnologyTag
                          image="ethereum.png"
                          title={`${t("tab8")}`}
                          subtitle=""
                          href="ethereum"
                          text="Ethereum"
                        />
                        <TechnologyTag
                          image="eos.png"
                          title={`${t("tab8")}`}
                          subtitle=""
                          href="eos"
                          text="EOS"
                        />
                        <TechnologyTag
                          image="neo.png"
                          title={`${t("tab8")}`}
                          subtitle=""
                          href="neo"
                          text="NEO"
                        />
                        <TechnologyTag
                          image="corda.png"
                          title={`${t("tab8")}`}
                          subtitle=""
                          href="corda"
                          text="Corda"
                        />
                        <TechnologyTag
                          image="multichain.png"
                          title={`${t("tab8")}`}
                          subtitle=""
                          href="multichain"
                          text="Multichain"
                        />
                        <TechnologyTag
                          image="stellar.png"
                          title={`${t("tab8")}`}
                          subtitle=""
                          href="stellar"
                          text="Stellar"
                        />
                        <TechnologyTag
                          image="tron.png"
                          title={`${t("tab8")}`}
                          subtitle=""
                          href="tron"
                          text="Tron"
                        />
                        <TechnologyTag
                          image="solidty.png"
                          title={`${t("tab8")}`}
                          subtitle=""
                          href="solidty"
                          text="Solidty"
                        />
                        <TechnologyTag
                          image="mssql.jpg"
                          title={`${t("tab9")}`}
                          subtitle={t("relational")}
                          href="mssql"
                          text="Microsoft Sql Server"
                        />
                        <TechnologyTag
                          image="postgresql.png"
                          title={`${t("tab9")}`}
                          subtitle={t("relational")}
                          href="postgresql"
                          text="PostgreSQL"
                        />
                        <TechnologyTag
                          image="mariadb-mysql.jpg"
                          title={`${t("tab9")}`}
                          subtitle={t("relational")}
                          href="mariadb-mysql"
                          text="MariaDB / MySQL"
                        />
                        <TechnologyTag
                          image="oracle.png"
                          title={`${t("tab9")}`}
                          subtitle={t("relational")}
                          href="oracle"
                          text="Oracle"
                        />
                        <TechnologyTag
                          image="sqlite.png"
                          title={`${t("tab9")}`}
                          subtitle={t("relational")}
                          href="sqlite"
                          text="SQLite"
                        />
                        <TechnologyTag
                          image="mongo.png"
                          title={`${t("tab9")}`}
                          subtitle="NoSql"
                          href="mongo"
                          text="MongoDB"
                        />
                        <TechnologyTag
                          image="cassandra.png"
                          title={`${t("tab9")}`}
                          subtitle="NoSql"
                          href="cassandra"
                          text="Apache Cassandra"
                        />
                        <TechnologyTag
                          image="redis.png"
                          title={`${t("tab9")}`}
                          subtitle="NoSql"
                          href="redis"
                          text="Redis"
                        />
                        <TechnologyTag
                          image="couchbase.png"
                          title={`${t("tab9")}`}
                          subtitle="NoSql"
                          href="couchbase"
                          text="Couchbase Server"
                        />
                        <TechnologyTag
                          image="dbeaver.png"
                          title={`${t("tab9")} / ${t("tab10")}`}
                          subtitle=""
                          href="dbeaver"
                          text="DBeaver"
                        />
                        <TechnologyTag
                          image="datagrip.png"
                          title={`${t("tab9")} / ${t("tab10")}`}
                          subtitle=""
                          href="datagrip"
                          text="DataGrip"
                        />
                        <TechnologyTag
                          image="jira.png"
                          title={`${t("tab10")} / ${t("tab11")}`}
                          subtitle=""
                          href="jira"
                          text="Jira"
                        />
                        <TechnologyTag
                          image="confluence.png"
                          title={`${t("tab10")} / ${t("tab11")}`}
                          subtitle=""
                          href="confluence"
                          text="Confluence"
                        />
                        <TechnologyTag
                          image="monday.png"
                          title={`${t("tab10")}`}
                          subtitle=""
                          href="monday"
                          text="Monday"
                        />
                        <TechnologyTag
                          image="trello.png"
                          title={`${t("tab10")}`}
                          subtitle=""
                          href="trello"
                          text="Trello"
                        />
                        <TechnologyTag
                          image="sharepoint.png"
                          title={`${t("tab10")} / ${t("tab11")}`}
                          subtitle=""
                          href="sharepoint"
                          text="Microsoft SharePoint"
                        />
                        <TechnologyTag
                          image="office.png"
                          title={`${t("tab10")} / ${t("tab11")}`}
                          subtitle=""
                          href="office"
                          text="Microsoft Office"
                        />
                        <TechnologyTag
                          image="visio.jpg"
                          title={`${t("tab10")}`}
                          subtitle=""
                          href="visio"
                          text="Microsoft Visio"
                        />
                        <TechnologyTag
                          image="doors.jpg"
                          title={`${t("tab10")}`}
                          subtitle=""
                          href="doors"
                          text="IBM Doors"
                        />
                        <TechnologyTag
                          image="selenium.png"
                          title={`${t("tab11")}`}
                          subtitle=""
                          href="selenium"
                          text="Selenium"
                        />
                        <TechnologyTag
                          image="appium.jpg"
                          title={`${t("tab11")}`}
                          subtitle=""
                          href="appium"
                          text="Appium"
                        />
                        <TechnologyTag
                          image="jmeter.png"
                          title={`${t("tab11")}`}
                          subtitle=""
                          href="appium"
                          text="Apache JMeter"
                        />
                        <TechnologyTag
                          image="postman.png"
                          title={`${t("tab11")}`}
                          subtitle=""
                          href="postman"
                          text="Postman"
                        />
                        <TechnologyTag
                          image="soapui.png"
                          title={`${t("tab11")}`}
                          subtitle=""
                          href="soapui"
                          text="SoapUI"
                        />
                        <TechnologyTag
                          image="aws.png"
                          title={`${t("tab12")}`}
                          subtitle=""
                          href="aws"
                          text="Amazon Web Services"
                        />
                        <TechnologyTag
                          image="azure.png"
                          title={`${t("tab12")}`}
                          subtitle=""
                          href="azure"
                          text="Azure"
                        />
                        <TechnologyTag
                          image="google-cloud.jpg"
                          title={`${t("tab12")}`}
                          subtitle=""
                          href="google-cloud"
                          text="Google Cloud"
                        />
                        <TechnologyTag
                          image="digital-ocean.png"
                          title={`${t("tab12")}`}
                          subtitle=""
                          href="digital-ocean"
                          text="DigitalOcean"
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab2")}</h2>
                        </div>
                      </div>
                      <h3>BACK END</h3>
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <TechnologyTag
                          image="net.png"
                          title={t("tab2")}
                          subtitle="Back End"
                          href=".net"
                          text="Microsoft .NET"
                        />
                        <TechnologyTag
                          image="java.png"
                          title={t("tab2")}
                          subtitle="Back End"
                          href="java"
                          text="Java"
                        />
                        <TechnologyTag
                          image="python.jpg"
                          title={t("tab2")}
                          subtitle="Back End"
                          href="python"
                          text="Python"
                        />
                        <TechnologyTag
                          image="node.png"
                          title={t("tab2")}
                          subtitle="Back End"
                          href="node"
                          text="Node.js"
                        />
                        <TechnologyTag
                          image="cpp.png"
                          title={t("tab2")}
                          subtitle="Back End"
                          href="cpp"
                          text="C++"
                        />
                        <TechnologyTag
                          image="go.png"
                          title={t("tab2")}
                          subtitle="Back End"
                          href="golang"
                          text="Golang"
                        />
                        <TechnologyTag
                          image="php.png"
                          title={t("tab2")}
                          subtitle="Back End"
                          href="php"
                          text="PHP"
                        />
                      </div>
                      <h3>FRONT END</h3>
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <TechnologyTag
                          image="html.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="html"
                          text="HTML"
                        />
                        <TechnologyTag
                          image="css.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="css"
                          text="CSS"
                        />
                        <TechnologyTag
                          image="js.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="javascript"
                          text="Javascript"
                        />
                        <TechnologyTag
                          image="bootstrap.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="bootstrap"
                          text="Bootstrap"
                        />
                        <TechnologyTag
                          image="net.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href=".net"
                          text="Microsoft .NET"
                        />
                        <TechnologyTag
                          image="php.png"
                          title={`${t("tab2")}`}
                          subtitle="Front End"
                          href="php"
                          text="PHP"
                        />
                        <TechnologyTag
                          image="java.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="java"
                          text="Java"
                        />
                        <TechnologyTag
                          image="angular.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="angular"
                          text="Angular"
                        />
                        <TechnologyTag
                          image="react.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="react"
                          text="React"
                        />
                        <TechnologyTag
                          image="vue.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="vue"
                          text="Vue"
                        />
                        <TechnologyTag
                          image="backbone.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="backbone"
                          text="Backbone"
                        />
                        <TechnologyTag
                          image="knockout.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="knockout"
                          text="Knockout"
                        />
                        <TechnologyTag
                          image="ember.png"
                          title={t("tab2")}
                          subtitle="Front End"
                          href="ember"
                          text="Ember"
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab3")}</h2>
                        </div>
                        <div className="row multi-columns-row ttm-boxes-spacing-5px">
                          <TechnologyTag
                            image="cpp.png"
                            title={t("tab3")}
                            subtitle=""
                            href="cpp"
                            text="C++"
                          />
                          <TechnologyTag
                            image="qt.png"
                            title={t("tab3")}
                            subtitle=""
                            href="qt"
                            text="Qt"
                          />
                          <TechnologyTag
                            image="electron.png"
                            title={t("tab3")}
                            subtitle=""
                            href="electron"
                            text="Electron"
                          />
                          <TechnologyTag
                            image="csharp.png"
                            title={t("tab3")}
                            subtitle=""
                            href="csharp"
                            text="C#"
                          />
                          <TechnologyTag
                            image="net.png"
                            title={t("tab3")}
                            subtitle=""
                            href=".net"
                            text="Microsoft .Net"
                          />
                          <TechnologyTag
                            image="java.png"
                            title={t("tab3")}
                            subtitle=""
                            href="java"
                            text="Java"
                          />
                          <TechnologyTag
                            image="python.jpg"
                            title={t("tab3")}
                            subtitle=""
                            href="python"
                            text="Python"
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab4")}</h2>
                        </div>
                        <div className="row multi-columns-row ttm-boxes-spacing-5px">
                          <TechnologyTag
                            image="ios.jpg"
                            title={t("tab4")}
                            subtitle=""
                            href="ios"
                            text="iOS"
                          />
                          <TechnologyTag
                            image="android.jpg"
                            title={t("tab4")}
                            subtitle=""
                            href="android"
                            text="Android"
                          />
                          <TechnologyTag
                            image="react-native.png"
                            title={t("tab4")}
                            subtitle=""
                            href="react-native"
                            text="React Native"
                          />
                          <TechnologyTag
                            image="flutter.jpeg"
                            title={t("tab4")}
                            subtitle=""
                            href="flutter"
                            text="Flutter"
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab5")}</h2>
                        </div>
                        <div className="row multi-columns-row ttm-boxes-spacing-5px">
                          <TechnologyTag
                            image="balsamiq.png"
                            title={t("tab5")}
                            subtitle=""
                            href="balsamiq"
                            text="Balsamiq Mockup"
                          />
                          <TechnologyTag
                            image="drawio.png"
                            title={t("tab5")}
                            subtitle=""
                            href="drawio"
                            text="Draw.io"
                          />
                          <TechnologyTag
                            image="omnigraffle.png"
                            title={t("tab5")}
                            subtitle=""
                            href="omnigraffle"
                            text="OmniGraffle"
                          />
                          <TechnologyTag
                            image="sketch.png"
                            title={t("tab5")}
                            subtitle=""
                            href="sketch"
                            text="Sketch"
                          />
                          <TechnologyTag
                            image="xd.png"
                            title={t("tab5")}
                            subtitle=""
                            href="xd"
                            text="Adobe XD"
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab6")}</h2>
                        </div>
                        <div className="row multi-columns-row ttm-boxes-spacing-5px">
                          <TechnologyTag
                            image="docker.jpg"
                            title={t("tab6")}
                            subtitle=""
                            href="docker"
                            text="Docker"
                          />
                          <TechnologyTag
                            image="kubernetes.png"
                            title={t("tab6")}
                            subtitle=""
                            href="kubernetes"
                            text="Kubernetes"
                          />
                          <TechnologyTag
                            image="prometheus.png"
                            title={t("tab6")}
                            subtitle=""
                            href="prometheus"
                            text="Prometheus"
                          />
                          <TechnologyTag
                            image="grafana.png"
                            title={t("tab6")}
                            subtitle=""
                            href="grafana"
                            text="Grafana"
                          />
                          <TechnologyTag
                            image="elk.png"
                            title={t("tab6")}
                            subtitle=""
                            href="elk"
                            text="ELK Stack"
                          />
                          <TechnologyTag
                            image="teamcity.png"
                            title={t("tab6")}
                            subtitle=""
                            href="teamcity"
                            text="TeamCity"
                          />
                          <TechnologyTag
                            image="jenkins.png"
                            title={t("tab6")}
                            subtitle=""
                            href="jenkins"
                            text="Jenkins"
                          />
                          <TechnologyTag
                            image="bamboo.png"
                            title={t("tab6")}
                            subtitle=""
                            href="bamboo"
                            text="Bamboo"
                          />
                          <TechnologyTag
                            image="azure-devops.jpg"
                            title={t("tab6")}
                            subtitle=""
                            href="azure-devops"
                            text="Azure DevOps"
                          />
                          <TechnologyTag
                            image="gitlab-cicd.png"
                            title={t("tab6")}
                            subtitle=""
                            href="gitlab-cicd"
                            text="Gitlab CI CD"
                          />
                          <TechnologyTag
                            image="ansible.jpg"
                            title={t("tab6")}
                            subtitle=""
                            href="ansible"
                            text="Ansible"
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab7")}</h2>
                        </div>
                        <div className="row multi-columns-row ttm-boxes-spacing-5px">
                          <TechnologyTag
                            image="python.jpg"
                            title={t("tab7")}
                            subtitle=""
                            href="python"
                            text="Python"
                          />
                          <TechnologyTag
                            image="matlab.jpg"
                            title={t("tab7")}
                            subtitle=""
                            href="matlab"
                            text="MATLAB"
                          />
                          <TechnologyTag
                            image="r.png"
                            title={t("tab7")}
                            subtitle=""
                            href="r"
                            text="R"
                          />
                          <TechnologyTag
                            image="pytorch.png"
                            title={t("tab7")}
                            subtitle=""
                            href="pytorch"
                            text="PyTorch"
                          />
                          <TechnologyTag
                            image="tenserflow.png"
                            title={t("tab7")}
                            subtitle=""
                            href="tenserflow"
                            text="TenserFlow"
                          />
                          <TechnologyTag
                            image="keras.png"
                            title={t("tab7")}
                            subtitle=""
                            href="keras"
                            text="Keras"
                          />
                          <TechnologyTag
                            image="torch.png"
                            title={t("tab7")}
                            subtitle=""
                            href="torch"
                            text="Torch"
                          />
                          <TechnologyTag
                            image="mahout.png"
                            title={t("tab7")}
                            subtitle=""
                            href="mahout"
                            text="Apache Mahout"
                          />
                          <TechnologyTag
                            image="opencv.png"
                            title={t("tab7")}
                            subtitle=""
                            href="opencv"
                            text="OpenCV"
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab8")}</h2>
                        </div>
                        <div className="row multi-columns-row ttm-boxes-spacing-5px">
                          <TechnologyTag
                            image="hyperledger.png"
                            title={t("tab8")}
                            subtitle=""
                            href="hyperledger"
                            text="Hyperledger"
                          />
                          <TechnologyTag
                            image="ethereum.png"
                            title={t("tab8")}
                            subtitle=""
                            href="ethereum"
                            text="Ethereum"
                          />
                          <TechnologyTag
                            image="eos.png"
                            title={t("tab8")}
                            subtitle=""
                            href="eos"
                            text="EOS"
                          />
                          <TechnologyTag
                            image="neo.png"
                            title={t("tab8")}
                            subtitle=""
                            href="neo"
                            text="NEO"
                          />
                          <TechnologyTag
                            image="corda.png"
                            title={t("tab8")}
                            subtitle=""
                            href="corda"
                            text="Corda"
                          />
                          <TechnologyTag
                            image="multichain.png"
                            title={t("tab8")}
                            subtitle=""
                            href="multichain"
                            text="Multichain"
                          />
                          <TechnologyTag
                            image="stellar.png"
                            title={t("tab8")}
                            subtitle=""
                            href="stellar"
                            text="Stellar"
                          />
                          <TechnologyTag
                            image="tron.png"
                            title={t("tab8")}
                            subtitle=""
                            href="tron"
                            text="Tron"
                          />
                          <TechnologyTag
                            image="solidty.png"
                            title={t("tab8")}
                            subtitle=""
                            href="solidty"
                            text="Solidty"
                          />
                          <TechnologyTag
                            image="js.png"
                            title={t("tab8")}
                            subtitle=""
                            href="javascript"
                            text="Javascript"
                          />
                          <TechnologyTag
                            image="node.png"
                            title={t("tab8")}
                            subtitle=""
                            href="node"
                            text="Node.js"
                          />
                          <TechnologyTag
                            image="go.png"
                            title={t("tab8")}
                            subtitle=""
                            href="golang"
                            text="Golang"
                          />
                          <TechnologyTag
                            image="cpp.png"
                            title={t("tab8")}
                            subtitle=""
                            href="cpp"
                            text="C++"
                          />
                          <TechnologyTag
                            image="csharp.png"
                            title={t("tab8")}
                            subtitle=""
                            href="csharp"
                            text="C#"
                          />
                          <TechnologyTag
                            image="python.jpg"
                            title={t("tab8")}
                            subtitle=""
                            href="python"
                            text="Python"
                          />
                          <TechnologyTag
                            image="java.png"
                            title={t("tab8")}
                            subtitle=""
                            href="java"
                            text="Java"
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab9")}</h2>
                        </div>
                      </div>
                      <h3>{t("relational")}</h3>
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <TechnologyTag
                          image="mssql.jpg"
                          title={t("tab9")}
                          subtitle={t("relational")}
                          href="mssql"
                          text="Microsoft Sql Server"
                        />
                        <TechnologyTag
                          image="postgresql.png"
                          title={t("tab9")}
                          subtitle={t("relational")}
                          href="postgresql"
                          text="PostgreSQL"
                        />
                        <TechnologyTag
                          image="mariadb-mysql.jpg"
                          title={t("tab9")}
                          subtitle={t("relational")}
                          href="mariadb-mysql"
                          text="MariaDB / MySQL"
                        />
                        <TechnologyTag
                          image="oracle.png"
                          title={t("tab9")}
                          subtitle={t("relational")}
                          href="oracle"
                          text="Oracle"
                        />
                        <TechnologyTag
                          image="sqlite.png"
                          title={t("tab9")}
                          subtitle={t("relational")}
                          href="sqlite"
                          text="SQLite"
                        />
                      </div>
                      <h3>NoSql</h3>
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <TechnologyTag
                          image="mongo.png"
                          title={t("tab9")}
                          subtitle={t("relational")}
                          href="mongo"
                          text="MongoDB"
                        />
                        <TechnologyTag
                          image="cassandra.png"
                          title={t("tab9")}
                          subtitle="NoSql"
                          href="cassandra"
                          text="Apache Cassandra"
                        />
                        <TechnologyTag
                          image="redis.png"
                          title={t("tab9")}
                          subtitle="NoSql"
                          href="redis"
                          text="Redis"
                        />
                        <TechnologyTag
                          image="couchbase.png"
                          title={t("tab9")}
                          subtitle="NoSql"
                          href="couchbase"
                          text="Couchbase Server"
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab10")}</h2>
                        </div>
                      </div>
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <TechnologyTag
                          image="jira.png"
                          title={t("tab10")}
                          subtitle=""
                          href="jira"
                          text="Jira"
                        />
                        <TechnologyTag
                          image="confluence.png"
                          title={t("tab10")}
                          subtitle=""
                          href="confluence"
                          text="Confluence"
                        />
                        <TechnologyTag
                          image="azure-devops.jpg"
                          title={t("tab10")}
                          subtitle=""
                          href="azure-devops"
                          text="Azure DevOps"
                        />
                        <TechnologyTag
                          image="monday.png"
                          title={t("tab10")}
                          subtitle=""
                          href="monday"
                          text="Monday"
                        />
                        <TechnologyTag
                          image="trello.png"
                          title={t("tab10")}
                          subtitle=""
                          href="trello"
                          text="Trello"
                        />
                        <TechnologyTag
                          image="sharepoint.png"
                          title={t("tab10")}
                          subtitle=""
                          href="sharepoint"
                          text="Microsoft SharePoint"
                        />
                        <TechnologyTag
                          image="office.png"
                          title={t("tab10")}
                          subtitle=""
                          href="office"
                          text="Microsoft Office"
                        />
                        <TechnologyTag
                          image="visio.jpg"
                          title={t("tab10")}
                          subtitle=""
                          href="visio"
                          text="Microsoft Visio"
                        />
                        <TechnologyTag
                          image="doors.jpg"
                          title={t("tab10")}
                          subtitle=""
                          href="doors"
                          text="IBM Doors"
                        />
                        <TechnologyTag
                          image="dbeaver.png"
                          title={t("tab10")}
                          subtitle=""
                          href="dbeaver"
                          text="DBeaver"
                        />
                        <TechnologyTag
                          image="datagrip.png"
                          title={t("tab10")}
                          subtitle=""
                          href="datagrip"
                          text="DataGrip"
                        />
                        <TechnologyTag
                          image="balsamiq.png"
                          title={t("tab10")}
                          subtitle=""
                          href="balsamiq"
                          text="Balsamiq"
                        />
                        <TechnologyTag
                          image="drawio.png"
                          title={t("tab10")}
                          subtitle=""
                          href="drawio"
                          text="Drawio"
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab11")}</h2>
                        </div>
                      </div>
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <TechnologyTag
                          image="jira.png"
                          title={t("tab11")}
                          subtitle=""
                          href="jira"
                          text="Jira"
                        />
                        <TechnologyTag
                          image="confluence.png"
                          title={t("tab11")}
                          subtitle=""
                          href="confluence"
                          text="Confluence"
                        />
                        <TechnologyTag
                          image="azure-devops.jpg"
                          title={t("tab11")}
                          subtitle=""
                          href="azure-devops"
                          text="Azure DevOps"
                        />
                        <TechnologyTag
                          image="sharepoint.png"
                          title={t("tab11")}
                          subtitle=""
                          href="sharepoint"
                          text="Microsoft SharePoint"
                        />
                        <TechnologyTag
                          image="office.png"
                          title={t("tab11")}
                          subtitle=""
                          href="office"
                          text="Microsoft Office"
                        />
                        <TechnologyTag
                          image="selenium.png"
                          title={t("tab11")}
                          subtitle=""
                          href="selenium"
                          text="Selenium"
                        />
                        <TechnologyTag
                          image="appium.jpg"
                          title={t("tab11")}
                          subtitle=""
                          href="appium"
                          text="Appium"
                        />
                        <TechnologyTag
                          image="jmeter.png"
                          title={t("tab11")}
                          subtitle=""
                          href="appium"
                          text="Apache JMeter"
                        />
                        <TechnologyTag
                          image="postman.png"
                          title={t("tab11")}
                          subtitle=""
                          href="postman"
                          text="Postman"
                        />

                        <TechnologyTag
                          image="soapui.png"
                          title={t("tab11")}
                          subtitle=""
                          href="soapui"
                          text="SoapUI"
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">{t("tab12")}</h2>
                        </div>
                      </div>
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <TechnologyTag
                          image="aws.png"
                          title={t("tab12")}
                          subtitle=""
                          href="aws"
                          text="Amazon Web Services"
                        />
                        <TechnologyTag
                          image="azure.png"
                          title={t("tab12")}
                          subtitle=""
                          href="azure"
                          text="Azure"
                        />
                        <TechnologyTag
                          image="google-cloud.jpg"
                          title={t("tab12")}
                          subtitle=""
                          href="google-cloud"
                          text="Google Cloud"
                        />
                        <TechnologyTag
                          image="digital-ocean.png"
                          title={t("tab12")}
                          subtitle=""
                          href="digital-ocean"
                          text="DigitalOcean"
                        />
                      </div>
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
