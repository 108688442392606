import { closeModal } from "../../modules/modal/actions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal as ModalDialog } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const toMessage = (payload) => {
  if (payload.validationResult) {
    let message = payload.message + "\n";
    const errors = [];
    payload.validationResult.errors.forEach((error) => {
      errors.push(`${error.field} ${error.message}`);
    });
    message += errors.join(", ");
    return message;
  }

  if (payload.message) {
    return payload.message;
  }

  return payload;
};

function Modal() {
  const { t } = useTranslation("modal");
  const dispatch = useDispatch();
  const isOpen = useSelector((selector) => {
    return selector.modal.modal.isOpen;
  });
  const options =
    useSelector((selector) => {
      return selector.modal.modal.options;
    }) || {};

  return (
    <ModalDialog
      show={isOpen}
      onHide={(ev) => {
        if (options.onCancel) {
          options.onCancel(ev);
        }
        dispatch(closeModal());
      }}
      size="lg"
      centered
    >
      <ModalDialog.Header closeButton>
        <ModalDialog.Title>
          {options.title || t("modalTitle")}
        </ModalDialog.Title>
      </ModalDialog.Header>
      <ModalDialog.Body>{toMessage(options.message)}</ModalDialog.Body>
      <ModalDialog.Footer>
        {options.showCancel ? (
          <Button
            variant="secondary"
            onClick={(ev) => {
              if (options.onCancel) {
                options.onCancel(ev);
              }
              dispatch(closeModal());
            }}
          >
            {options.cancelText || t("modalCancelText")}
          </Button>
        ) : null}
        <Button
          variant="primary"
          onClick={(ev) => {
            if (options.onOk) {
              options.onOk(ev);
            }
            dispatch(closeModal());
          }}
        >
          {options.okText || t("modalOkText")}
        </Button>
      </ModalDialog.Footer>
    </ModalDialog>
  );
}

export default Modal;
