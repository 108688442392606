import {
  SEND_CONTACT_MAIL_SUCCESS,
  SEND_CONTACT_MAIL_ERROR,
  RESET_CONTACT_MAIL,
} from "./actions";

const initState = {};

const contactReducer = (state = initState, action) => {
  switch (action.type) {
    case SEND_CONTACT_MAIL_SUCCESS:
      return {
        ...state,
        success: true,
      };
    case SEND_CONTACT_MAIL_ERROR:
      return {
        ...state,
        error: action.error,
        success: false,
      };
    case RESET_CONTACT_MAIL:
      return {
        initState,
      };
    default:
      return state;
  }
};

export default contactReducer;
