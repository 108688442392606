import _ from "../helpers/@loadash";
import { withFormsy } from "formsy-react";
import React from "react";

function RadioButton(props) {
  const importedProps = _.pick(props, [
    "className",
    "label",
    "name",
    "id",
    "inline",
  ]);

  const value = props.value || "";

  function changeValue(event) {
    props.setValue(event.currentTarget.value);
    if (props.onChange) {
      props.onChange(event.currentTarget.value);
    }
  }

  const formClassName =
    "form-check " +
    (importedProps.inline
      ? "form-check-inline"
      : importedProps.className || "col-sm-6 col-md-6");

  return (
    <div
      className={formClassName}
      style={{
        backgroundColor: "transparent",
        padding: "17px 24px",
      }}
    >
      <input
        className="form-check-input bg-white border"
        type="radio"
        value={value}
        name={importedProps.name}
        id={importedProps.id}
        onChange={changeValue}
      />
      <label
        className="form-check-label"
        htmlFor={importedProps.id}
        style={{ marginLeft: 20 }}
      >
        {importedProps.label}
      </label>
    </div>
  );
}

export default React.memo(withFormsy(RadioButton));
