import React, { useState, useEffect, useRef } from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import Formsy from "formsy-react";
import TextBox from "../formsy/textbox";
import TextArea from "../formsy/textarea";
import PhoneBox from "../formsy/phonebox";
import CheckBox from "../formsy/checkbox";
import RadioButton from "../formsy/radiobutton";
import FileUpload from "../formsy/fileupload";
import { validatePhoneNumber } from "../helpers/phoneValidator";
import {
  sendQuotationMail,
  resetQuotationMail,
} from "../../modules/quotation/actions";
import { openModal } from "../../modules/modal/actions";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, clearBusy } from "../../modules/busyIndicator/actions";
import { Helmet } from "react-helmet";

export default function Quotation() {
  const { t } = useTranslation("quotation");
  const [canSubmit, setCanSubmit] = useState(false);
  const [file, setFile] = useState();
  const [consultancyServices, setConsultancyServices] = useState([]);
  const error = useSelector((state) => state.quotation.error);
  const success = useSelector((state) => state.quotation.success);
  const formRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      dispatch(clearBusy());
      dispatch(resetQuotationMail());
      dispatch(
        openModal({
          title: "E-Posta Gönderimi",
          message: "E-Posta başarılı bir şekilde gönderilmiştir.",
          onOk: () => {
            formRef.current.reset();
          },
          onCancel: () => {
            formRef.current.reset();
          },
        })
      );
    } else if (error) {
      dispatch(clearBusy());
      dispatch(
        openModal({
          title: "E-Posta Gönderimi",
          message: error,
        })
      );
      dispatch(resetQuotationMail());
    }
  });

  function disableButton() {
    setCanSubmit(false);
  }

  function enableButton() {
    setCanSubmit(true);
  }

  function submit(model) {
    dispatch(setBusy("E-Posta gönderiliyor."));
    const payload = Object.assign({}, model);
    if (file) {
      payload.file = file;
    } else {
      payload.file = null;
    }

    payload.consultancyServices = consultancyServices;

    dispatch(sendQuotationMail(payload));
  }

  function onFileChanged(file) {
    setFile(file);
  }

  function onConsultancyChanged(consultancy) {
    if (consultancyServices.includes(consultancy)) {
      const index = consultancyServices.indexOf(consultancy);
      consultancyServices.splice(index, 1);
    } else {
      consultancyServices.push(consultancy);
    }
    setConsultancyServices(consultancyServices);
  }

  let submitClassName = "mt-3 ttm-btn ttm-btn-size-md";
  if (canSubmit) {
    submitClassName += " ttm-bgcolor-skincolor";
  }
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Get A Quote`}</title>
        <meta
          name="description"
          content={`info@runicbytes.com | +90 850 441 81 10 | Ankara Teknopark TGB Yerleşkesi İvedik OSB Mahallesi 2224. Cad. No:1 İç Kapı No:116 B Blok Üst Zemin Kat Yenimahalle 06378 ANKARA TÜRKİYE`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("getAQuote")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("getAQuote")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <section className="ttm-row zero-padding-section clearfix">
        <div className="container-sm">
          <div className="row no-gutters">
            {/* row */}
            <div className="col-lg-12">
              <div className="spacing-2 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>
                {/* section title */}
                {/* section title end */}
                <Formsy
                  ref={formRef}
                  onValidSubmit={submit}
                  onValid={enableButton}
                  onInvalid={disableButton}
                  id="ttm-quote-form"
                  className="row ttm-quote-form clearfix"
                >
                  <div className="col-lg-12">
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h2 className="title">{t("contractTypes")}</h2>
                      </div>
                      <a
                        href={process.env.PUBLIC_URL + "/contracts"}
                        style={{ textDecoration: "underline" }}
                      >
                        <strong>{t("contractsLink")}</strong>
                      </a>
                      <div
                        style={{
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        <div className="col-lg-12">
                          <div className="row">
                            <RadioButton
                              name="contractType"
                              id="offshoreSoftwareDevelopment"
                              value="1"
                              label={t("offshoreSoftwareDevelopment")}
                              className="col-sm-6 col-md-4 col-lg-3"
                            ></RadioButton>
                            <RadioButton
                              name="contractType"
                              id="hourlyConsultancy"
                              value="2"
                              label={t("hourlyConsultancy")}
                              className="col-sm-6 col-md-4 col-lg-3"
                            ></RadioButton>{" "}
                            <RadioButton
                              name="contractType"
                              id="progressPayment"
                              value="3"
                              label={t("progressPayment")}
                              className="col-sm-6 col-md-4 col-lg-3"
                            ></RadioButton>{" "}
                            <RadioButton
                              name="contractType"
                              id="fixedPrice"
                              value="4"
                              label={t("fixedPrice")}
                              className="col-sm-6 col-md-4 col-lg-3"
                            ></RadioButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="section-title with-desc clearfix"
                      style={{ marginTop: 20 }}
                    >
                      <div className="title-header">
                        <h2 className="title">{t("ourServices")}</h2>
                      </div>
                      <a
                        href={process.env.PUBLIC_URL + "/ourServices"}
                        style={{ textDecoration: "underline" }}
                      >
                        <strong>{t("ourServicesLink")}</strong>
                      </a>
                      <div
                        style={{
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        <div className="col-lg-12">
                          <div className="row">
                            <CheckBox
                              name="softwareConsultancy"
                              label={t("softwareConsultancy")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(1)}
                            ></CheckBox>
                            <CheckBox
                              name="webDesktopDevelopment"
                              label={t("webDesktopDevelopment")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(2)}
                            ></CheckBox>
                            <CheckBox
                              name="mobileDevelopment"
                              label={t("mobileDevelopment")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(3)}
                            ></CheckBox>
                            <CheckBox
                              name="uiUxDesign"
                              label={t("uiUxDesign")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(4)}
                            ></CheckBox>
                            <CheckBox
                              name="devOpsSecOpsSupport"
                              label={t("devOpsSecOpsSupport")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(5)}
                            ></CheckBox>
                            <CheckBox
                              name="aiMachineLearningSolutions"
                              label={t("aiMachineLearningSolutions")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(6)}
                            ></CheckBox>
                            <CheckBox
                              name="blockChainSolutions"
                              label={t("blockChainSolutions")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(7)}
                            ></CheckBox>
                            <CheckBox
                              name="noSqlRelationalSolutions"
                              label={t("noSqlRelationalSolutions")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(8)}
                            ></CheckBox>
                            <CheckBox
                              name="technicalSupport"
                              label={t("technicalSupport")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(9)}
                            ></CheckBox>
                            <CheckBox
                              name="baSupport"
                              label={t("baSupport")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(10)}
                            ></CheckBox>
                            <CheckBox
                              name="testSupport"
                              label={t("testSupport")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(11)}
                            ></CheckBox>
                            <CheckBox
                              name="eTransformation"
                              label={t("eTransformation")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(12)}
                            ></CheckBox>
                            <CheckBox
                              name="agileTransformation"
                              label={t("agileTransformation")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(13)}
                            ></CheckBox>
                            <CheckBox
                              name="grantSupportConsultancy"
                              label={t("grantSupportConsultancy")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(14)}
                            ></CheckBox>
                            <CheckBox
                              name="expertiseServices"
                              label={t("expertiseServices")}
                              className="col-sm-6 col-md-4 col-lg-3"
                              onChange={() => onConsultancyChanged(15)}
                            ></CheckBox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="section-title with-desc clearfix"
                      style={{ marginTop: 20 }}
                    >
                      <div className="title-header">
                        <h2 className="title">{t("writeToUs")}</h2>
                      </div>
                    </div>
                  </div>
                  <TextBox
                    name="firstName"
                    type="text"
                    className="col-sm-6 col-md-6"
                    placeholder={t("firstName")}
                    required
                    validations={{
                      maxLength: 30,
                    }}
                    validationErrors={{
                      maxLength: t("firstNameMaxLength"),
                    }}
                  ></TextBox>
                  <TextBox
                    name="lastName"
                    type="text"
                    className="col-sm-6 col-md-6"
                    placeholder={t("lastName")}
                    required
                    validations={{
                      maxLength: 30,
                    }}
                    validationErrors={{
                      maxLength: t("lastNameMaxLength"),
                    }}
                  ></TextBox>
                  <PhoneBox
                    name="phone"
                    placeholder={t("phoneNumber2")}
                    className="col-sm-6 col-md-6"
                    required
                    validations={{
                      isValidPhoneNumberValidation: function (values, value) {
                        return validatePhoneNumber(values, value, true);
                      },
                    }}
                  ></PhoneBox>
                  <TextBox
                    name="email"
                    type="email"
                    className="col-sm-6 col-md-6"
                    placeholder={t("mail2")}
                    required
                    validations="isEmail"
                    validationError={t("wrongEmailFormat")}
                  ></TextBox>

                  <TextArea
                    name="message"
                    rows={5}
                    placeholder={t("writeAMessage")}
                    required
                    validations={{
                      maxLength: 1000,
                      minLength: 30,
                    }}
                    validationErrors={{
                      maxLength: t("messageMaxLength"),
                      minLength: t("messageMinLength"),
                    }}
                  ></TextArea>
                  <FileUpload
                    name="file"
                    className="col-sm-12 col-md-12"
                    label={t("uploadFile")}
                    onChange={(file) => onFileChanged(file)}
                  ></FileUpload>
                  <div className="col-md-12">
                    <div className="text-left">
                      <button
                        type="submit"
                        id="submit"
                        className={submitClassName}
                        disabled={!canSubmit}
                      >
                        {t("send")}
                      </button>
                    </div>
                  </div>
                </Formsy>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
    </div>
  );
}
