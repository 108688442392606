import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Helmet } from "react-helmet";

export default function OurWayOfWorking() {
  const { t } = useTranslation("ourWayOfWorking");
  return (
    <div className="site-main">
      {" "}
      <Helmet>
        <title>{`Runic Bytes - Our Way Of Working`}</title>
        <meta
          name="description"
          content={`We ask tough questions, conduct important consultations, and look at the problem from all angles to find creative solutions. We share results with you every time we move forward and work together to plan for the future.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("ourWayOfWorking")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("ourWayOfWorking")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      {/* about-services-section */}
      <section className="ttm-row about-services-section clearfix">
        <div className="container-xl">
          <div className="row align-items-center">
            {/* row */}
            <div className="col-lg-12">
              <div className="res-991-mt-30">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">{t("motto1")}</h2>
                  </div>
                  <div className="title-desc">
                    <p>{t("text1")}</p>
                    <p>{t("text2")}</p>
                    <p>{t("text3")}</p>
                    <p>{t("text4")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("principle1")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("principle2")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("principle3")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("principle4")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("principle5")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("principle6")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor"></i>
                          <span className="ttm-list-li-content">
                            {t("principle7")}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="position-relative">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper text-sm-center">
                  <img
                    className="img-fluid"
                    src="../images/scrum.png"
                    title="work cycle"
                    alt="SCRUM"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="res-991-mt-30">
                {/* section title */}
                {/* section title end */}
                {/* row */}

                <div className="title-header">
                  <h2 className="title">{t("motto2")}</h2>
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("step1")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("step1Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                {/* separator */}
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("step2")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("step2Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                {/* separator */}
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("step3")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("step3Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                {/* separator */}
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("step4")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("step4Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                {/* separator */}
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("step5")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("step5Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* about-services-section end */}
      {/* services-slide-section */}
      <section className="ttm-row services-slide-section ttm-bgcolor-grey ttm-bg ttm-bgimage-yes bg-img14 clearfix">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
        <div className="container-xl">
          <div className="row align-items-center">
            {/* row */}
            <div className="col-lg-12 col-xl-12 col-md-12">
              {/* section-title */}
              <div className="title-header">
                <h5>{t("dev")}</h5>
                <h2 className="title">{t("agileDev")}</h2>
              </div>
              {/* section-title end */}
            </div>
          </div>
          <div className="ttm-box-col-wrapper">
            {/* featured-imagebox-services */}
            <div className="featured-imagebox featured-imagebox-services style1 ">
              <div
                className="featured-content box-shadow nohover"
                style={{ margin: 0, hover: "none" }}
              >
                <div className="featured-title">
                  {/* featured-title */}
                  <h5>SCRUM</h5>
                </div>
                <div className="featured-desc">
                  {/* featured-title */}
                  <p>{t("scrum")}</p>
                </div>
              </div>
            </div>
            {/* featured-imagebox-services */}
          </div>

          <div className="ttm-box-col-wrapper">
            {/* featured-imagebox-services */}
            <div className="featured-imagebox featured-imagebox-services style1 ">
              <div
                className="featured-content box-shadow nohover"
                style={{ margin: 0, textAlign: "unset" }}
              >
                <div className="col-lg-12 col-xl-12 col-md-12">
                  {/* section-title */}
                  <div className="title-header">
                    <h5>
                      <a
                        href={process.env.PUBLIC_URL + "/faq"}
                        style={{ textDecoration: "underline" }}
                      >
                        <strong style={{ color: "#f16141" }}>{t("faq")}</strong>
                      </a>
                    </h5>
                  </div>
                  {/* section-title end */}
                </div>
                <div className="col-lg-12 res-991-mt-30">
                  {/* accordian */}
                  <Accordion preExpanded={["foo"]}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{t("faq1")}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{t("faq1Text1")}</p>
                        <p>{t("faq1Text2")}</p>
                        <p>{t("faq1Text3")}</p>
                        <p>{t("faq1Text4")}</p>
                        <p>{t("faq1Text5")}</p>
                        <p>{t("faq1Text6")}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{t("faq2")}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{t("faq2Text")}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{t("faq3")}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          {t("faq3Text")}
                          <br />
                          <a
                            href={process.env.PUBLIC_URL + "/contracts"}
                            style={{ textDecoration: "underline" }}
                          >
                            <strong style={{ color: "#f16141" }}>
                              {t("faq3TextDetail")}
                            </strong>
                          </a>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{t("faq4")}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{t("faq4Text")}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{t("faq5")}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{t("faq5Text")}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* services-slide-section end */}
      {/* portfolio-section */}
    </div>
  );
}
