import { SET_BUSY, CLEAR_BUSY } from "./actions";

const initialState = {
  busyIndicatorState: { isBusy: false, busyContent: "" },
};

const busyIndicatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSY: {
      return {
        ...state,
        busyIndicatorState: { isBusy: true, busyContent: action.message },
      };
    }
    case CLEAR_BUSY: {
      return {
        ...state,
        busyIndicatorState: { isBusy: false, busyContent: "" },
      };
    }
    default: {
      return state;
    }
  }
};

export default busyIndicatorReducer;
