import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function SmartBytes() {
  const { t } = useTranslation("smartBytes");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - SMART BYTES`}</title>
        <meta name="description" content={`COMING SOON`} />
      </Helmet>
      <Header />{" "}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">SMART BYTES</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>SMART BYTES</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* error 404 */}
      <section className="error-404 ttm-textcolor-grey">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="ttm-big-icon ttm-textcolor-skincolor">
                <i className="fas fa-smile-beam" />
              </div>
              <header className="page-header">
                <h1 className="page-title">{t("comingSoon")}</h1>
              </header>
              <div className="page-content"> </div>
              <a
                className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor mb-15"
                href={process.env.PUBLIC_URL + "/"}
              >
                {t("backToHome")}
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* error 404 end*/}
    </div>
  );
}
