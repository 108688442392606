import React from "react";
import { useTranslation } from "react-i18next";

export function Menu() {
  const { t } = useTranslation("menu");
  return (
    <nav id="menu" role="navigation" className="menu">
      <ul>
        <li className="menu-hasdropdown">
          <a href={process.env.PUBLIC_URL + "/"}>{t("homepage")}</a>
        </li>
        <li className="menu-hasdropdown">
          <a href={process.env.PUBLIC_URL + "/aboutus"}>{t("aboutus")}</a>
          <ul className="menu-dropdown">
            <li>
              <a href={process.env.PUBLIC_URL + "/aboutus"}>{t("aboutus")}</a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/companyInfo"}>
                {t("companyInfo")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/missionVision"}>
                {t("missionVision")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/ourPolicies"}>
                {t("ourPolicies")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/ourValues"}>
                {t("ourValues")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/ourWayOfWorking"}>
                {t("ourWayOfWorking")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/whyUs"}>{t("whyUs")}</a>
            </li>

            <li>
              <a href={process.env.PUBLIC_URL + "/contracts"}>
                {t("contracts")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/packageList"}>
                {t("packageList")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/faq"}>{t("faq")}</a>
            </li>
          </ul>
        </li>
        <li className="menu-hasdropdown">
          <a href={process.env.PUBLIC_URL + "/ourServices"}>
            {t("ourServices")}
          </a>
          <ul className="menu-dropdown" style={{ marginLeft: -130 }}>
            <li>
              <a href={process.env.PUBLIC_URL + "/ourServices"}>
                {t("ourServices")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/softwareConsultancy"}>
                {t("softwareConsultancy")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/webDesktopDevelopment"}>
                {t("webDesktopDevelopment")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/mobileDevelopment"}>
                {t("mobileDevelopment")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/uiUxDesign"}>
                {t("uiUxDesign")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/devOpsSecOpsSupport"}>
                {t("devOpsSecOpsSupport")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/aiMachineLearningSolutions"}>
                {t("aiMachineLearningSolutions")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/blockChainSolutions"}>
                {t("blockChainSolutions")}
              </a>
            </li>
          </ul>
          <ul className="menu-dropdown" style={{ marginLeft: 121 }}>
            <li>
              <a href={process.env.PUBLIC_URL + "/noSqlRelationalSolutions"}>
                {t("noSqlRelationalSolutions")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/technicalSupport"}>
                {t("technicalSupport")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/baSupport"}>
                {t("baSupport")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/testSupport"}>
                {t("testSupport")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/eTransformation"}>
                {t("eTransformation")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/agileTransformation"}>
                {t("agileTransformation")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/grantSupportConsultancy"}>
                {t("grantSupportConsultancy")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/expertiseServices"}>
                {t("expertiseServices")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/freeConsulting"}>
                {t("freeConsulting")}
              </a>
            </li>
          </ul>
        </li>
        <li className="menu-hasdropdown">
          <a href={process.env.PUBLIC_URL + "/technologies/all"}>
            {t("technologies")}
          </a>
          <ul className="menu-dropdown">
            <li>
              <a href={process.env.PUBLIC_URL + "/technologies/all"}>
                {t("technologies")}
              </a>
            </li>
          </ul>
        </li>
        <li className="menu-hasdropdown">
          <a href={process.env.PUBLIC_URL + "/ourProducts/all"}>
            {t("ourProducts")}
          </a>
          <ul className="menu-dropdown">
            <li>
              <a href={process.env.PUBLIC_URL + "/ourProducts/all"}>
                {t("ourProducts")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/smartBytes"}>Smart Bytes</a>
            </li>
          </ul>
        </li>
        <li className="menu-hasdropdown">
          <a href={process.env.PUBLIC_URL + "/contact"}>{t("contact")}</a>
          <ul className="menu-dropdown">
            <li>
              <a href={process.env.PUBLIC_URL + "/contact"}>{t("contact")}</a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/appointment"}>
                {t("appointment")}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/quotation"}>
                {t("quotation")}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
}
