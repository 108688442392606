import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Privacy() {
  const { t } = useTranslation("privacy");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Privacy And Cookies Policy`}</title>
        <meta
          name="description"
          content={`We are aware of the importance of privacy. Therefore, we are committed to providing measures that protect your personal information when you visit us or decide to share your data with us. The following information is designed to help visitors, partners, customers and applicants understand what information we collect from our site and how we treat and use the information thereafter. This Privacy and Cookie Policy (“Policy”) describes our practices, including your choices and rights regarding the collection, use and processing of certain information, including your personal information, by Runic Bytes.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("privacy")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("privacy")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-12">
              {/* ttm-service-single-content-are */}
              {/* ttm-service-single-content-are */}
              <div className="ttm-service-single-content-area">
                {/* section title */}
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <p>{t("text1")}</p>
                    <p>{t("text2")}</p>
                    <h4>{t("chapter1")}</h4>
                    <p>{t("chapter1Text1")}</p>
                    <p>{t("chapter1Text2")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Issue1")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Issue2")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Issue3")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Issue4")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h5>{t("chapter1Title1")}</h5>
                    <p>{t("chapter1Title1Text")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title1Issue1")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title1Issue2")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h5>{t("chapter1Title2")}</h5>
                    <p>{t("chapter1Title2Text")}</p>
                    <h5>{t("chapter1Title3")}</h5>
                    <p>{t("chapter1Title3Text1")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue1")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue2")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue3")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue4")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue5")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue6")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue7")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue8")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue9")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue10")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue11")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue12")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue13")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue14")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter1Title3Issue15")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p>{t("chapter1Title3Text2")}</p>
                    <p>{t("chapter1Title3Text3")}</p>
                    <p>{t("chapter1Title3Text4")}</p>
                    <h4>{t("chapter2")}</h4>
                    <p>{t("chapter2Text1")}</p>
                    <p>{t("chapter2Text2")}</p>
                    <p>{t("chapter2Text3")}</p>
                    <p>{t("chapter2Text4")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter2Issue1")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter2Issue2")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter2Issue3")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter2Issue4")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter2Issue5")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p>{t("chapter2Text5")}</p>
                    <h4>{t("chapter3")}</h4>
                    <p>{t("chapter3Text1")}</p>
                    <p>{t("chapter3Text2")}</p>
                    <p>{t("chapter3Text3")}</p>
                    <h4>{t("chapter4")}</h4>
                    <p>{t("chapter4Text1")}</p>
                    <p>{t("chapter4Text2")}</p>
                    <p>{t("chapter4Text3")}</p>
                    <p>{t("chapter4Text4")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue1")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue2")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue3")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue4")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue5")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue6")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue7")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue8")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p>{t("chapter4Text5")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue9")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue10")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue11")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p>{t("chapter4Text6")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue12")}
                          </span>
                        </li>{" "}
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue13")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p>{t("chapter4Text7")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue14")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue15")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter4Issue16")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p>{t("chapter4Text8")}</p>
                    <p>{t("chapter4Text9")}</p>
                    <p>{t("chapter4Text10")}</p>
                    <h4>{t("chapter5")}</h4>
                    <p>{t("chapter5Text1")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter5Issue1")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter5Issue2")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter5Issue3")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p>{t("chapter5Text2")}</p>
                    <h4>{t("chapter6")}</h4>
                    <p>{t("chapter6Text1")}</p>
                    <p>{t("chapter6Text2")}</p>
                    <h4>{t("chapter7")}</h4>
                    <p>{t("chapter7Text1")}</p>
                    <p>{t("chapter7Text2")}</p>
                    <p>{t("chapter7Text3")}</p>
                    <p>{t("chapter7Text4")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter7Issue1")}
                          </span>
                        </li>
                        <p>{t("chapter7Issue1Text")}</p>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter7Issue2")}
                          </span>
                          <p>{t("chapter7Issue2Text")}</p>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter7Issue3")}
                          </span>
                          <p>{t("chapter7Issue3Text")}</p>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("chapter7Issue4")}
                          </span>
                          <p>{t("chapter7Issue4Text")}</p>
                        </li>
                      </ul>
                    </div>
                    <p>{t("chapter7Text6")}</p>
                    <h5>{t("chapter7Title")}</h5>
                    <p>{t("chapter7TitleText")}</p>
                    <h4>{t("chapter8")}</h4>
                    <p>{t("chapter8Text")}</p>
                  </div>
                </div>
              </div>
              {/* ttm-service-single-content-are end */}
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
}
