import React from "react";
import { Menu } from "./menu";
import MobileMenu from "./MobileMenu";

import { useTranslation } from "react-i18next";

export function Header() {
  const { t, i18n } = useTranslation("header");
  return (
    <header id="masthead" className="header ttm-header-style-02">
      {/* ttm-topbar-wrapper */}
      <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-5">
              <ul className="top-contact text-left">
                <li>
                  <i className="fas fa-map-marker-alt" />
                  &nbsp;&nbsp;{t("officeAddressShort")}
                </li>
                <li>
                  <i className="far fa-envelope" />
                  &nbsp;&nbsp;
                  <a href="mailto:info@runicbytes.com">info@runicbytes.com</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-7">
              <div className="topbar-right text-right d-flex justify-content-end">
                <ul className="top-contact">
                  <li>
                    <i className="far fa-clock" />
                    &nbsp;&nbsp;{t("officeHours")}
                  </li>
                </ul>
                <div className="ttm-social-links-wrapper list-inline">
                  <ul className="social-icons">
                    <li>
                      <a
                        className=" tooltip-bottom"
                        data-tooltip="Spotify"
                        href="https://open.spotify.com/user/ixa8y2sqog0dso7cdeky9695z?si=7c0451b4a01e459e"
                      >
                        <i className="fab fa-spotify" />
                      </a>
                    </li>
                    <li>
                      <a
                        className=" tooltip-bottom"
                        data-tooltip="Instagram"
                        href="https://www.instagram.com/runicbytes/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className=" tooltip-bottom"
                        data-tooltip="Twitter"
                        href="https://twitter.com/RunicBytes"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        className=" tooltip-bottom"
                        data-tooltip="Linkedin"
                        href="https://www.linkedin.com/company/runic-bytes/"
                      >
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a
                        className=" tooltip-bottom"
                        data-tooltip="GitHub"
                        href="https://github.com/RunicBytes"
                      >
                        <i className="fab fa-github"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className=" tooltip-bottom"
                        data-tooltip="Youtube"
                        href="https://www.youtube.com/channel/UCa6uu3joJ8lysHhxgWSOxwg"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className=" tooltip-bottom"
                        data-tooltip="Twitch"
                        href="https://www.twitch.tv/runicbytes"
                      >
                        <i className="fab fa-twitch" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="header-btn">
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                    href={process.env.PUBLIC_URL + "/quotation"}
                  >
                    {t("getAQuote")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ttm-topbar-wrapper end */}
      {/* ttm-header-wrap */}
      <div className="ttm-header-wrap">
        <div className="ttm-bgcolor-white">
          <div className="container-xl">
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-rowalign-items-center">
                  {/* site-branding */}
                  <div
                    className="site-branding mr-auto"
                    style={{
                      margin: "auto",
                    }}
                  >
                    <a
                      className="home-link"
                      href={process.env.PUBLIC_URL + "/"}
                      title="Runic Bytes"
                      rel="home"
                    >
                      <img
                        id="logo-img"
                        className="img-center"
                        src="images/logo.png"
                        alt="logo"
                      />
                    </a>
                  </div>
                  {/* site-branding end */}
                  {/* widget-info */}
                  <div className="widget_info d-flex flex-row align-items-center justify-content-end">
                    <div className="widget_icon">
                      <i className="flaticon-call" />
                    </div>
                    <div className="widget_content">
                      <h5 className="widget_title">{t("workingHoursTitle")}</h5>
                      <p className="widget_desc">{t("workingHours")}</p>
                    </div>
                  </div>
                  {/* widget-info end */}
                  {/* widget-info */}
                  <div className="widget_info d-flex flex-row align-items-center justify-content-end">
                    <div className="widget_icon">
                      <i className="flaticon-clock" />
                    </div>
                    <div className="widget_content">
                      <h5 className="widget_title">{t("support7-24")}</h5>
                      <p className="widget_desc">
                        <a href="mailto:support@runicbytes.com">
                          support@runicbytes.com
                        </a>
                      </p>
                    </div>
                  </div>
                  {/* widget-info end */}
                  {/* widget-info */}
                  <div className="widget_info d-flex flex-row align-items-center justify-content-end">
                    <div className="widget_icon">
                      <i className="flaticon-global-1" />
                    </div>
                    <div className="widget_content">
                      <h5 className="widget_title">{t("ourAddress")}</h5>
                      <p className="widget_desc mb-2">{t("officeAddress1")}</p>
                      <p className="widget_desc mb-2">{t("officeAddress2")}</p>
                      <p className="widget_desc">{t("officeAddress3")}</p>
                    </div>
                  </div>
                  {/* widget-info end */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="navbar" className="ttm-stickable-header clearfix">
          <div className="ttm-bgcolor-grey">
            <div className="container-xl">
              <div className="row site-header-menu">
                {/*site-navigation */}
                <div className="col-12 site-navigation">
                  <div className="ttm-right-header">
                    <ul
                      className="ttm-header-icons position-relative"
                      style={{ zIndex: 999 }}
                    >
                      <li
                        className="ttm-header-search-link ttm-bgcolor-skincolor ttm-textcolor-white"
                        style={{ marginLeft: 10 }}
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            i18n.changeLanguage("en-US");
                          }}
                          className=" tooltip-bottom"
                          data-tooltip="English"
                        >
                          <span>EN</span>
                        </a>
                      </li>
                      <li
                        className="ttm-header-search-link ttm-bgcolor-skincolor ttm-textcolor-white"
                        style={{ marginLeft: 10 }}
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            i18n.changeLanguage("tr-TR");
                          }}
                          className=" tooltip-bottom"
                          data-tooltip="Türkçe"
                        >
                          <span>TR</span>
                        </a>
                      </li>
                      <li
                        className="ttm-header-search-link ttm-bgcolor-skincolor ttm-textcolor-white"
                        style={{ marginLeft: 10 }}
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            i18n.changeLanguage("de-DE");
                          }}
                          className=" tooltip-bottom"
                          data-tooltip="Deutsch"
                        >
                          <span>DE</span>
                        </a>
                      </li>
                    </ul>
                    <div className="ttm-custombutton ttm-bgcolor-skincolor">
                      <span className="ttm-textcolor-white">
                        {" "}
                        {t("helpLine")} : {t("helpLinePhone")}
                      </span>
                    </div>
                  </div>
                  <Menu />
                  <div className="mobilemenu">
                    <MobileMenu />
                  </div>
                </div>
                {/*site-navigation end*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ttm-header-wrap end*/}
    </header>
  );
}
export default Header;
