import mailService from "../../services/mail/mailService";

export const SEND_APPOINTMENT_MAIL_SUCCESS = "SEND_APPOINTMENT_MAIL_SUCCESS";
export const SEND_APPOINTMENT_MAIL_ERROR = "SEND_APPOINTMENT_MAIL_ERROR";
export const RESET_APPOINTMENT_MAIL = "RESET_APPOINTMENT_MAIL";

export const resetAppointmentMail = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_APPOINTMENT_MAIL,
    });
  };
};

const sendAppointmentMailSuccess = () => {
  return {
    type: SEND_APPOINTMENT_MAIL_SUCCESS,
  };
};

const sendAppointmentMailError = (error) => {
  return {
    type: SEND_APPOINTMENT_MAIL_ERROR,
    error,
  };
};

export const sendAppointmentMail = (model) => {
  return (dispatch) => {
    mailService
      .sendAppointment(
        model.firstName,
        model.lastName,
        model.phone,
        model.email,
        model.subject,
        model.date,
        model.message
      )
      .then((response) => {
        response.data.error
          ? dispatch(sendAppointmentMailError(response.data.error.error))
          : dispatch(sendAppointmentMailSuccess());
      })
      .catch((response) => {
        dispatch(sendAppointmentMailError(response.error));
      });
  };
};
