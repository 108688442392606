import React from "react";
import Sidebar from "../services/Sidebar";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function FreeConsulting() {
  const { t } = useTranslation("freeConsulting");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Free Consultancy`}</title>
        <meta
          name="description"
          content="If you want to be sure of the quality of our services, we can first offer you a pilot project or give you a free consultation. In order to be useful to you, we evaluate your projects with our team, without any employment contract or charge. Whether the concept and theory is applicable in practice for your current project, we realize free of charge with a POC study and presentation."
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("freeConsulting")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("freeConsulting")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <section className="ttm-row about-services-section clearfix">
        <div className="container-xl">
          <div className="row">
            {/* row */}
            <div className="col-lg-9 content-area order-lg-2">
              <div className="res-991-mt-30">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">{t("title")}</h2>
                  </div>
                  <div className="title-desc">
                    <p>{t("titleText1")}</p>
                    <p>{t("titleText2")}</p>
                    <p>{t("titleText3")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 widget-area">
              <Sidebar />
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
    </div>
  );
}
