import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import PackageTag from "./PackageTag";
import { Helmet } from "react-helmet";

export default function PackageList() {
  const { t } = useTranslation("packageList");

  return (
    <div className="site-main">
      {" "}
      <Helmet>
        <title>{`Runic Bytes - Service Packages`}</title>
        <meta
          name="description"
          content={`We offer the opportunity to build a dedicated team of offshore professionals. Get service from the best experts in Turkey. You can hire the entire team for a certain period of time, or you can arrange for special experts to work individually for you according to your schedules. We offer flexible outsourcing packages, enabling you to build a dedicated team on a full-time, part-time or hourly contract basis. Either use one of the ready-made packages or create teams from existing experts to suit your needs.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("packageList")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("packageList")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="ttm-row tab-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h1 className="title">{t("title1")}</h1>
                </div>
              </div>
              <div className="col-md-12">
                <p>{t("title1Text1")}</p>
                <p>{t("titleText2")}</p>
                <h3>{t("title3")}</h3>
                <p>{t("title3Text1")}</p>
                <h6>{t("title4")}</h6>
                <p>{t("title4Text1")}</p>
                <h6>{t("title5")} </h6>
                <p>{t("title5Text1")}</p>
                <h6>{t("title6")}</h6>
                <p>{t("title6Text1")}</p>
                <h3>{t("title7")}</h3>
                <PackageTag
                  isTeam
                  title={t("team1")}
                  description={t("team1Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team2")}
                  description={t("team2Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team3")}
                  description={t("team3Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team1")}
                  description={t("team1Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team4")}
                  description={t("team4Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team5")}
                  description={t("team5Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team6")}
                  description={t("team6Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team7")}
                  description={t("team7Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team8")}
                  description={t("team8Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team9")}
                  description={t("team9Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team10")}
                  description={t("team10Text")}
                />
                <PackageTag
                  isTeam
                  title={t("team11")}
                  description={t("team11Text")}
                />
                <h3 className="mt-20">{t("title8")}</h3>
                <p>{t("title8Text")}</p>
                <div className="col-12 mt-20">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h5>{t("hiringOptions1")}</h5>
                      <p>{t("hiringOptions1Text")}</p>
                    </div>
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style2 left-icon icon-align-top">
                    <div className="featured-icon">
                      {/* featured-icon */}
                      <div
                        style={{ margin: 4 }}
                        className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
                      >
                        <strong>{t("hiringOption1")}</strong>
                      </div>
                    </div>
                    <div className="featured-icon">
                      <div
                        style={{ margin: 4 }}
                        className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
                      >
                        <strong> {t("hiringOption2")}</strong>
                      </div>
                    </div>
                    <div className="featured-icon">
                      <div
                        style={{ margin: 4 }}
                        className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
                      >
                        <strong>{t("hiringOption3")}</strong>
                      </div>
                    </div>
                    <div className="featured-icon">
                      <div
                        style={{ margin: 4 }}
                        className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
                      >
                        <strong>{t("hiringOption4")}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-20">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h5>{t("hiringOptions2")}</h5>
                      <p>{t("hiringOptions2Text")}</p>
                    </div>
                  </div>
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style2 left-icon icon-align-top">
                    <div className="featured-icon">
                      {/* featured-icon */}
                      <div
                        style={{ margin: 4 }}
                        className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
                      >
                        <strong> {t("hiringOption5")}</strong>
                      </div>
                    </div>
                    <div className="featured-icon">
                      <div
                        style={{ margin: 4 }}
                        className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
                      >
                        <strong> {t("hiringOption6")}</strong>
                      </div>
                    </div>
                    <div className="featured-icon">
                      <div
                        style={{ margin: 4 }}
                        className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
                      >
                        <strong>{t("hiringOption7")}</strong>
                      </div>
                    </div>
                    <div className="featured-icon">
                      <div
                        style={{ margin: 4 }}
                        className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
                      >
                        <strong>{t("hiringOption4")}</strong>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row no-gutters mt-4 mb-4">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon mt-20">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role1")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role2")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role3")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role4")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role5")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role6")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role7")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role8")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role9")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role10")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role11")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role12")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role13")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role14")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role15")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon mt-20">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role16")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role17")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role18")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role19")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role20")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role21")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role22")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role23")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role24")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role25")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role26")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role27")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("role28")}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
