import mailService from "../../services/mail/mailService";

export const SEND_QUOTATION_MAIL_SUCCESS = "SEND_QUOTATION_MAIL_SUCCESS";
export const SEND_QUOTATION_MAIL_ERROR = "SEND_QUOTATION_MAIL_ERROR";
export const RESET_QUOTATION_MAIL = "RESET_QUOTATION_MAIL";

export const resetQuotationMail = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_QUOTATION_MAIL,
    });
  };
};

const sendQuotationMailSuccess = () => {
  return {
    type: SEND_QUOTATION_MAIL_SUCCESS,
  };
};

const sendQuotationMailError = (error) => {
  return {
    type: SEND_QUOTATION_MAIL_ERROR,
    error,
  };
};

export const sendQuotationMail = (model) => {
  return (dispatch) => {
    mailService
      .sendQuotation(
        model.file,
        model.contractType,
        model.consultancyServices,
        model.firstName,
        model.lastName,
        model.phone,
        model.email,
        model.message
      )
      .then((response) => {
        response.data.error
          ? dispatch(sendQuotationMailError(response.data.error.error))
          : dispatch(sendQuotationMailSuccess());
      })
      .catch((response) => {
        dispatch(sendQuotationMailError(response.error));
      });
  };
};
