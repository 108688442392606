import React from "react";
import Sidebar from "../services/Sidebar";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function BaSupport() {
  const { t } = useTranslation("baSupport");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Business Analysis Support`}</title>
        <meta
          name="description"
          content="We offer a full range of Business Analysis services to support organizations of all sizes covering many different industries. Using effective communication, our Business Analysts act as a link between various audiences, including users, IT professionals and other stakeholders to collect, analyze, communicate and validate requirements for changes in business processes, policies and information systems."
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("baSupport")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("baSupport")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 content-area order-lg-2">
              {/* ttm-service-single-content-are */}
              {/* ttm-service-single-content-are */}
              <div className="ttm-service-single-content-area">
                {/* section title */}
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <p>{t("categoryText")}</p>
                    <h5>{t("title")}</h5>
                    <p>{t("titleText1")}</p>
                    <p>{t("titleText2")}</p>
                    <p>{t("titleText3")}</p>
                    <p>{t("titleText4")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("solution1")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("solution2")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("solution3")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("solution4")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <a
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                      style={{ width: "100%", textAlign: "center" }}
                      href={
                        process.env.PUBLIC_URL +
                        "/technologies/businessAnalysis"
                      }
                    >
                      {t("technologies")}
                    </a>
                  </div>
                </div>
              </div>
              {/* ttm-service-single-content-are end */}
              <div className="ttm-service-single-content-area">
                <div className="ttm-service-description">
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src="../images/ba.jpeg"
                          alt="Business Analysis"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="res-767-mt-20">{t("title1")}</h5>
                      <p>{t("title1Text")}</p>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <h5>{t("title2")}</h5>
                      <p>{t("title2Text")}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper res-767-mt-20">
                        <img
                          className="img-fluid"
                          src="../images/rtm.jpg"
                          alt="RTM"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src="../images/uat.jpg"
                          alt="UAT"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="res-767-mt-20">{t("title3")}</h5>
                      <p>{t("title3Text")}</p>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <h5>{t("title4")}</h5>
                      <p>{t("title4Text")}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper res-767-mt-20">
                        <img
                          className="img-fluid"
                          src="../images/change-management.jpg"
                          alt="Change Management"
                        />
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                </div>
              </div>
              {/* ttm-service-single-content-are end */}
            </div>
            <div className="col-lg-3 widget-area">
              <Sidebar />
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
}
