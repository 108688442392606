import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function Sidebar() {
  const { t } = useTranslation("sidebar");
  const history = useHistory();
  const path = history.location.pathname.substring(1);
  return (
    <div>
      <div className="widget widget-nav-menu">
        <ul className="widget-menu">
          <li>
            <a
              className={path === "ourServices" ? "active" : ""}
              href={process.env.PUBLIC_URL + "/ourServices"}
            >
              {t("ourServices")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/softwareConsultancy"}
              className={path === "softwareConsultancy" ? "active" : ""}
            >
              {t("softwareConsultancy")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/webDesktopDevelopment"}
              className={path === "webDesktopDevelopment" ? "active" : ""}
            >
              {t("webDesktopDevelopment")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/mobileDevelopment"}
              className={path === "mobileDevelopment" ? "active" : ""}
            >
              {t("mobileDevelopment")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/uiUxDesign"}
              className={path === "uiUxDesign" ? "active" : ""}
            >
              {t("uiUxDesign")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/devOpsSecOpsSupport"}
              className={path === "devOpsSecOpsSupport" ? "active" : ""}
            >
              {t("devOpsSecOpsSupport")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/aiMachineLearningSolutions"}
              className={path === "aiMachineLearningSolutions" ? "active" : ""}
            >
              {t("aiMachineLearningSolutions")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/blockChainSolutions"}
              className={path === "blockChainSolutions" ? "active" : ""}
            >
              {t("blockChainSolutions")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/noSqlRelationalSolutions"}
              className={path === "noSqlRelationalSolutions" ? "active" : ""}
            >
              {t("noSqlRelationalSolutions")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/technicalSupport"}
              className={path === "technicalSupport" ? "active" : ""}
            >
              {t("technicalSupport")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/baSupport"}
              className={path === "baSupport" ? "active" : ""}
            >
              {t("baSupport")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/testSupport"}
              className={path === "testSupport" ? "active" : ""}
            >
              {t("testSupport")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/eTransformation"}
              className={path === "eTransformation" ? "active" : ""}
            >
              {t("eTransformation")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/agileTransformation"}
              className={path === "agileTransformation" ? "active" : ""}
            >
              {t("agileTransformation")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/grantSupportConsultancy"}
              className={path === "grantSupportConsultancy" ? "active" : ""}
            >
              {t("grantSupportConsultancy")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/expertiseServices"}
              className={path === "expertiseServices" ? "active" : ""}
            >
              {t("expertiseServices")}
            </a>
          </li>
          <li>
            <a
              href={process.env.PUBLIC_URL + "/freeConsulting"}
              className={path === "freeConsulting" ? "active" : ""}
            >
              {t("freeConsulting")}
            </a>
          </li>
        </ul>
      </div>
      <div className="widget widget-text">
        <h3 className="widget-title">{t("aboutus")}</h3>
        <div className="ttm-author-widget">
          <div className="author-widget_img">
            <img
              className="author-img img-fluid"
              src="../images/logo.png"
              alt="Runic Bytes"
            />
          </div>
          <h4 className="author-name">Runic Bytes</h4>
          <p className="author-widget_text">{t("companyInfo")}</p>
        </div>
      </div>
      <div className="widget widget-text">
        <p className="author-widget_text">{t("memberOf")}</p>
        <p className="author-widget_text">{t("ahkMember")}</p>
        <a href="https://www.dtr-ihk.de/tr/">
          <img
            height={80}
            src="../images/ahk-member-logo.png"
            title="Deutsch-Türkische Industrie- und Handelskammer"
            alt="Deutsch-Türkische Industrie- und Handelskammer"
          />
        </a>
      </div>
      <div className="widget widget-download">
        <h3 className="widget-title">{t("brouchers")}</h3>
        <ul className="download">
          <li>
            <i className="far fa-file-pdf" />
            <a title="Download" href="javascript:void(0)">
              {t("packageList")}.pdf
            </a>
          </li>
          <li>
            <i className="far fa-file-pdf" />
            <a title="Download" href="javascript:void(0)">
              {t("presentation")}.pdf
            </a>
          </li>
        </ul>
      </div>
      <div className="widget widget_media_image">
        <div className="banner-img-box ttm-textcolor-white text-left">
          <div className="featured-content featured-content-banner">
            <i className="flaticon flaticon-call" />
            <div className="featured-title ttm-box-title">
              <h5>{t("howCanWeHelp")}</h5>
            </div>
            <div className="featured-desc">
              <p>{t("howCanWeHelpText")}</p>
            </div>
            <ul>
              <li>
                <i className="fa fa-phone" />
                {t("phone")}
              </li>
              <li>
                <i className="far fa-envelope" />
                <a href="mailto:info@example.com">info@runicbytes.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
