import {
  SEND_QUOTATION_MAIL_SUCCESS,
  SEND_QUOTATION_MAIL_ERROR,
  RESET_QUOTATION_MAIL,
} from "./actions";

const initState = {};

const quotationReducer = (state = initState, action) => {
  switch (action.type) {
    case SEND_QUOTATION_MAIL_SUCCESS:
      return {
        ...state,
        success: true,
      };
    case SEND_QUOTATION_MAIL_ERROR:
      return {
        ...state,
        error: action.error,
        success: false,
      };
    case RESET_QUOTATION_MAIL:
      return {
        initState,
      };
    default:
      return state;
  }
};

export default quotationReducer;
