import _ from "../helpers/@loadash";
import { withFormsy } from "formsy-react";
import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";

function PhoneBox(props) {
  const { t } = useTranslation("formsy");
  const importedProps = _.pick(props, [
    "className",
    "placeholder",
    "name",
    "type",
    "required",
    "pattern",
  ]);

  const value = props.value || "";

  function changeValue(event) {
    props.setValue(event);
    if (props.onChange) {
      props.onChange(event);
    }
  }

  const error = value
    ? isValidPhoneNumber(value)
      ? undefined
      : t("wrongFormat")
    : importedProps.required
    ? t("required")
    : undefined;
  return (
    <div className={importedProps.className || "col-sm-6 col-md-6"}>
      <PhoneInput
        international={false}
        name={importedProps.name}
        placeholder={importedProps.placeholder}
        value={value}
        onChange={changeValue}
        error={error}
      />
      <small>{error}</small>
    </div>
  );
}

export default React.memo(withFormsy(PhoneBox));
