const baseUrl = "https://api.runicbytes.com/api/";
export const appConfig = {
  baseUrl,
  rootUrl: baseUrl.replace("/api/", ""),
  route: (route) => {
    return `${baseUrl}${route}`;
  },
  isProduction: true,
};

export default appConfig;
