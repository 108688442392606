import React from "react";
import Header from "../layout/Header";
import Sidebar from "../services/Sidebar";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function OurServices() {
  const { t } = useTranslation("ourServices");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Our Services`}</title>
        <meta
          name="description"
          content={`Our service content covers the entire software development lifecycle and meets a variety of business needs.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("ourServices")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("ourServices")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 content-area order-lg-2">
              {/* ttm-service-single-content-are */}
              {/* ttm-service-single-content-are */}
              <div className="section-title with-desc text-left clearfix">
                <div className="title-header">
                  <h2>{t("motto")}</h2>
                </div>
              </div>
              <div className="ttm-service-description">
                <p>{t("text1")}</p>
                <p>{t("text2")}</p>
                <h5>{t("category1")}</h5>
                <p>{t("category1Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/technologies/all"}
                >
                  {t("category1Link")}
                  <i className="ti ti-angle-double-right" />
                </a>
                <h5>{t("category2")}</h5>
                <p>{t("category2Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/softwareConsultancy"}
                >
                  {t("category2Link")}
                  <i className="ti ti-angle-double-right" />
                </a>
                <h5>{t("category3")}</h5>
                <p>{t("category3Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/webDesktopDevelopment"}
                >
                  {t("category3Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category4")}</h5>
                <p>{t("category4Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/mobileDevelopment"}
                >
                  {t("category4Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category5")}</h5>
                <p>{t("category5Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/uiUxDesign"}
                >
                  {t("category5Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category6")}</h5>
                <p>{t("category6Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/devOpsSecOpsSupport"}
                >
                  {t("category6Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category7")}</h5>
                <p>{t("category7Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/aiMachineLearningSolutions"}
                >
                  {t("category7Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category8")}</h5>
                <p>{t("category8Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/blockChainSolutions"}
                >
                  {t("category8Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category9")}</h5>
                <p>{t("category9Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/packageList"}
                >
                  {t("category9Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category10")}</h5>
                <p>{t("category10Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/noSqlRelationalSolutions"}
                >
                  {t("category10Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category11")}</h5>
                <p>{t("category11Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/technicalSupport"}
                >
                  {t("category11Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category12")}</h5>
                <p>{t("category12Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/baSupport"}
                >
                  {t("category12Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category13")}</h5>
                <p>{t("category13Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/testSupport"}
                >
                  {t("category13Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category14")}</h5>
                <p>{t("category14Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/eTransformation"}
                >
                  {t("category14Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category15")}</h5>
                <p>{t("category15Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/agileTransformation"}
                >
                  {t("category15Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category16")}</h5>
                <p>{t("category16Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/grantSupportConsultancy"}
                >
                  {t("category16Link")}
                  <i className="ti ti-angle-double-right" />
                </a>{" "}
                <h5>{t("category17")}</h5>
                <p>{t("category17Text")}</p>
                <a
                  className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mb-20"
                  style={{ textTransform: "none" }}
                  href={process.env.PUBLIC_URL + "/expertiseServices"}
                >
                  {t("category17Link")}
                  <i className="ti ti-angle-double-right" />
                </a>
                <h2>{t("motto2")}</h2>
                <p>{t("text3")}</p>
                <p>{t("text4")}</p>
                <p>{t("text5")}</p>
                <p>
                  <strong>
                    <u>
                      <a
                        className="ttm-textcolor-darkgrey"
                        href={process.env.PUBLIC_URL + "/contracts"}
                      >
                        {t("link1")}
                      </a>
                    </u>
                  </strong>
                </p>
                <p>{t("text6")}</p>
                <p>
                  <strong>
                    <u>
                      <a
                        className="ttm-textcolor-darkgrey"
                        href={process.env.PUBLIC_URL + "/packageList"}
                      >
                        {t("link2")}
                      </a>
                    </u>
                  </strong>
                </p>
              </div>
            </div>
            <div className="col-lg-3 widget-area">
              <Sidebar />
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
}
