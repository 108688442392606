import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Contracts() {
  const { t } = useTranslation("contracts");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Contract Types`}</title>
        <meta
          name="description"
          content="Due to the existence of different types of needs and different structures in the sector, it is possible to meet the same need with a different service."
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("contracts")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("contracts")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      {/* aboutus-section */}
      <section className="ttm-row aboutus-section-style3 clearfix">
        <div className="container-xl">
          <div className="row">
            {/* row */}

            <div className="col-lg-12">
              <div className="res-991-mt-30">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">{t("motto")}</h2>
                  </div>
                  <div className="title-desc">
                    <p>{t("contractsText1")}</p>
                    <p>{t("contractsText2")}</p>
                    <p>{t("contractsText3")}</p>
                    <p>{t("contractsText4")}</p>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <div className="row mt-15">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style3 left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon */}
                        <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                          <i className="flaticon flaticon-global-1" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          {/* featured title */}
                          <h5>{t("contract1")}</h5>
                        </div>
                        <div className="featured-desc">
                          <p>{t("contract1Text1")}</p>
                          <p>{t("contract1Text2")}</p>
                          <p>{t("contract1Text3")}</p>
                          <p>{t("contract1Text4")}</p>
                          <p>{t("contract1Text5")}</p>
                          <p>{t("contract1Text6")}</p>
                          <p>{t("contract1Text7")}</p>
                          <p style={{ textDecoration: "underline" }}>
                            <strong>* {t("contract1Text8")}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                {/* separator */}
                <div className="row mt-15">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style3 left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon */}
                        <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                          <i className="flaticon flaticon-clock" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          {/* featured title */}
                          <h5>{t("contract2")}</h5>
                        </div>
                        <div className="featured-desc">
                          {/* featured desc */}
                          {/* featured desc */}
                          <p>{t("contract2Text1")}</p>
                          <p>{t("contract2Text2")}</p>
                          <p>{t("contract2Text3")}</p>
                          <p>{t("contract2Text4")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                {/* separator */}
                <div className="row mt-15">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style3 left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon */}
                        <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                          <i className="flaticon flaticon-data-1" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          {/* featured title */}
                          <h5>{t("contract3")}</h5>
                        </div>
                        <div className="featured-desc">
                          {/* featured desc */}
                          <p>{t("contract3Text1")}</p>
                          <p>{t("contract3Text2")}</p>
                          <p>{t("contract3Text3")}</p>
                          <p>{t("contract3Text4")}</p>
                          <p style={{ textDecoration: "underline" }}>
                            <strong>* {t("contract3Text5")}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                {/* separator */}
                <div className="row mt-15">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style3 left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon */}
                        <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                          <i className="flaticon flaticon-process" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          {/* featured title */}
                          <h5>{t("contract4")}</h5>
                        </div>
                        <div className="featured-desc">
                          <p>{t("contract4Text1")}</p>
                          <p>{t("contract4Text2")}</p>
                          {/* featured desc */}
                          <p>{t("contract4Text3")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>{" "}
      {/* zero-padding-section */}
      {/* zero-padding-section end */}
      {/* team-section */}
      {/* team-section end*/}
      {/* our-partner-section */}
      {/* our-partner-section end */}
      {/* blog-section */}
      {/* blog-section end */}
    </div>
  );
}
