import _ from "../helpers/@loadash";
import { withFormsy } from "formsy-react";
import React from "react";
import { useTranslation } from "react-i18next";

function FileUpload(props) {
  const { t } = useTranslation("formsy");
  const importedProps = _.pick(props, ["className", "label", "required"]);

  const { errorMessage, showRequired } = props;
  const value = props.value || "";
  let error = errorMessage;

  if (!error && showRequired) {
    error = t("required");
  }

  function changeValue(event) {
    props.setValue(event.currentTarget.value);
    if (props.onChange) {
      if (event.currentTarget.files && event.currentTarget.files.length !== 0) {
        props.onChange(event.currentTarget.files[0]);
      } else {
        props.onChange(null);
      }
    }
  }

  return (
    <div className={importedProps.className || "col-sm-6 col-md-6"}>
      <div className="form-group">
        <label htmlFor={importedProps.name}>{importedProps.label}</label>
        <input
          name={importedProps.name}
          required={importedProps.required}
          value={value}
          type="file"
          className="form-control-file bg-white border"
          onChange={changeValue}
        />
        <small>{error}</small>
      </div>
    </div>
  );
}

export default React.memo(withFormsy(FileUpload));
