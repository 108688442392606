import axios from "axios";
import appConfig from "../config/appConfig";
import i18next from "i18next";

const getGMT = () => {
  const date = new Date();
  const timeZoneOffset = date.getTimezoneOffset();
  const isPlus = timeZoneOffset < 0;
  const timeZone = Math.abs(timeZoneOffset / 60);
  if (timeZone === 0) {
    return "0";
  } else {
    return `${isPlus ? "+" : "-"}${timeZone}`;
  }
};

const configureAxios = () => {
  axios.defaults.baseURL = appConfig.baseUrl;
  axios.defaults.headers["Access-Control-Allow-Origin"] = appConfig.rootUrl;
  axios.defaults.headers["Access-Control-Allow-Methods"] = "*";
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers["Time-Zone"] = getGMT();

  axios.interceptors.request.use(
    (request) => {
      request.headers["Accept-Language"] = i18next.language;

      if (!appConfig.isProduction) {
        const headers = {
          ...request.headers.common,
          ...request.headers[request.method],
          ...request.headers,
        };

        ["common", "get", "post", "head", "put", "patch", "delete"].forEach(
          (header) => {
            delete headers[header];
          }
        );

        const requestLog = `${new Date()} | Request: ${request.method.toUpperCase()} | ${
          request.url
        } | ${JSON.stringify(request.data)} | ${JSON.stringify(headers)}`;

        /* eslint-disable no-console */
        console.log("Request:", requestLog);
      }

      return request;
    },
    (error) => {
      if (!appConfig.isProduction) {
        /* eslint-disable no-console */
        console.log("Request Error:", error);
      }

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (!appConfig.isProduction) {
        const responseLog = `${new Date()} | Response: ${
          response.status
        } | ${JSON.stringify(response.data)}`;
        /* eslint-disable no-console */
        console.log("Request Error:", responseLog);
      }

      if (response.data.error) {
        return Promise.reject(response.data);
      }

      return response;
    },
    (error) => {
      if (error.message === "Network Error") {
        return Promise.reject({
          message: "Ağ Hatası. Servisden Yanıt Alınamıyor!",
        });
      } else if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
    }
  );
};

export default configureAxios;
