import { combineReducers } from "redux";
import { RESET } from "./constant";
import contactReducer from "../modules/contact/reducer";
import appointmentReducer from "../modules/appointment/reducer";
import quotationReducer from "../modules/quotation/reducer";
import busyIndicatorReducer from "../modules/busyIndicator/reducer";
import modalReducer from "../modules/modal/reducer";

const appReducer = combineReducers({
  contact: contactReducer,
  busyIndicator: busyIndicatorReducer,
  modal: modalReducer,
  appointment: appointmentReducer,
  quotation: quotationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
