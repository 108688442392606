import _ from "../helpers/@loadash";
import { withFormsy } from "formsy-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { default as DateTime } from "react-datetime-picker";

function DateTimePicker(props) {
  const { t, i18n } = useTranslation("formsy");
  const language = i18n.language;
  const importedProps = _.pick(props, [
    "className",
    "name",
    "required",
    "minDate",
  ]);

  const { errorMessage, showRequired } = props;
  const value = props.value || "";
  let error = errorMessage;

  if (!error && showRequired) {
    error = t("required");
  }

  function changeValue(event) {
    props.setValue(event);
    if (props.onChange) {
      props.onChange(event);
    }
  }

  return (
    <div className={importedProps.className || "col-sm-6 col-md-6"}>
      <DateTime
        onChange={changeValue}
        name={importedProps.name}
        value={value}
        locale={language}
        required={importedProps.required}
        minDate={importedProps.minDate}
      />
      <small>{error}</small>
    </div>
  );
}

export default React.memo(withFormsy(DateTimePicker));
