import React, { useState } from "react";
import Header from "../layout/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function OurProducts() {
  const { t } = useTranslation("ourProducts");
  const { selectedTabId } = useParams();
  const history = useHistory();
  let selectedTabIndex = 0;
  switch (selectedTabId) {
    case "all":
      selectedTabIndex = 0;
      break;
    case "projectManagement":
      selectedTabIndex = 1;
      break;
    default:
      history.push("/404");
  }
  const [tabIndex, setTabIndex] = useState(selectedTabIndex | 0);
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Our Products`}</title>
        <meta
          name="description"
          content={`Our software products speed up your work and provide reliable and traceable results.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("ourProducts")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("ourProducts")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="ttm-row tab-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h1 className="title">{t("title")}</h1>
                </div>
              </div>
              <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => {
                    setTabIndex(index);
                    history.push(
                      "/ourProducts/" +
                        (index === 0 ? "all" : "projectManagement")
                    );
                  }}
                >
                  <TabList className="text-uppercase mb-4">
                    <Tab>
                      <span>{t("tab1")}</span>
                    </Tab>
                    <Tab>
                      <span>{t("tab2")} </span>
                    </Tab>
                  </TabList>
                  <div className="content-tab">
                    <TabPanel>
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                          <div className="featured-imagebox featured-imagebox-portfolio style2">
                            <div className="featured-thumbnail">
                              <img
                                className="img-fluid"
                                src={`../images/smartbytes.png`}
                                alt="Smart Bytes"
                              />
                            </div>
                            <div className="featured-content">
                              <div className="category">
                                <p>{t("tab2")}</p>
                              </div>
                              <div className="featured-title">
                                <h5>
                                  <a
                                    href={
                                      process.env.PUBLIC_URL + "/smartBytes"
                                    }
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Smart Bytes
                                  </a>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row multi-columns-row ttm-boxes-spacing-5px">
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                          <div className="featured-imagebox featured-imagebox-portfolio style2">
                            <div className="featured-thumbnail">
                              <img
                                className="img-fluid"
                                src={`../images/smartbytes.png`}
                                alt="Smart Bytes"
                              />
                            </div>
                            <div className="featured-content">
                              <div className="category">
                                <p>{t("tab2")}</p>
                              </div>
                              <div className="featured-title">
                                <h5>
                                  <a
                                    href={
                                      process.env.PUBLIC_URL + "/smartBytes"
                                    }
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Smart Bytes
                                  </a>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
