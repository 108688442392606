import React from "react";
import { useTranslation } from "react-i18next";
import {
  MDBAnimation,
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBContainer,
  MDBRow,
} from "mdbreact";
import { useHistory } from "react-router-dom";

export default function CarouselPage() {
  const { t } = useTranslation("carousel");
  const history = useHistory();
  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCarousel
          activeItem={1}
          length={2}
          showControls={true}
          showIndicators={false}
          id="Home_banner"
        >
          <MDBCarouselInner>
            <MDBCarouselItem itemId="1">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="../images/handshacke.jpg"
                  alt="Runic Bytes"
                />
              </MDBView>
              <MDBCarouselCaption>
                <div className="container-xl">
                  <div className="carousel-caption-inner banner1">
                    <MDBAnimation className="slideInUp" count={1} delay="2s">
                      <h2 className="ttm-textcolor-darkgrey flipInY">
                        {t("motto1")}
                      </h2>
                    </MDBAnimation>
                    <MDBAnimation className="slideInUp" count={1} delay="2.5s">
                      <p className="text">{t("motto1Text")}</p>
                    </MDBAnimation>
                    <MDBAnimation className="slideInUp" count={1} delay="3s">
                      <p>
                        <button
                          className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white"
                          onClick={() => {
                            history.push("/contact");
                          }}
                        >
                          {t("discussYourProject")}{" "}
                        </button>
                        &nbsp; &nbsp;
                        <button
                          className="ttm-btn btn-inline ttm-btn-size-md ttm-textcolor-darkgrey ml-3"
                          onClick={() => {
                            history.push("/quotation");
                          }}
                        >
                          <i className="ti-arrow-circle-right" />
                          {t("getAQuote")}.
                        </button>
                      </p>
                    </MDBAnimation>
                  </div>
                </div>
              </MDBCarouselCaption>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="2">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="../images/worldwide.jpg"
                  alt="Runic Bytes Worldwide"
                />
              </MDBView>
              <MDBCarouselCaption>
                <div className="container-xl">
                  <div className="carousel-caption-inner banner2">
                    <MDBAnimation className="slideInUp" count={1} delay="5s">
                      <h2 className="ttm-textcolor-darkgrey">{t("motto2")}</h2>
                    </MDBAnimation>
                    <MDBAnimation className="slideInUp" count={1} delay="5.5s">
                      <p className="text">{t("motto2Text1")}</p>
                      <p className="text">{t("motto2Text2")}</p>
                    </MDBAnimation>
                    <MDBAnimation className="slideInUp" count={1} delay="6s">
                      <p>
                        <button
                          className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3"
                          onClick={() => {
                            history.push("/contact");
                          }}
                        >
                          {t("discussYourProject")}
                        </button>
                        <button
                          className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                          onClick={() => {
                            history.push("/quotation");
                          }}
                        >
                          {t("getAQuote")}.
                        </button>
                      </p>
                    </MDBAnimation>
                  </div>
                </div>
              </MDBCarouselCaption>
            </MDBCarouselItem>
          </MDBCarouselInner>
        </MDBCarousel>
      </MDBRow>
    </MDBContainer>
  );
}
