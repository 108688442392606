import React from "react";
import Sidebar from "../services/Sidebar";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function TestSupport() {
  const { t } = useTranslation("testSupport");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Testing Support`}</title>
        <meta
          name="description"
          content={`With our support of testing processes, we help our customers bring high-quality products to market in a more efficient, predictable and effective way than they could have achieved on their own, with minimal rework due to errors and poor performance.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("testSupport")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("testSupport")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 content-area order-lg-2">
              {/* ttm-service-single-content-are */}
              {/* ttm-service-single-content-are */}
              <div className="ttm-service-single-content-area">
                {/* section title */}
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <p>{t("categoryText")}</p>
                    <h5>{t("title")}</h5>
                    <p>{t("titleText1")}</p>
                    <p>{t("titleText2")}</p>
                    <a
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                      style={{ width: "100%", textAlign: "center" }}
                      href={process.env.PUBLIC_URL + "/technologies/testing"}
                    >
                      {t("technologies")}
                    </a>
                  </div>
                </div>
              </div>
              {/* ttm-service-single-content-are end */}
              <div className="ttm-service-single-content-area">
                <div className="ttm-service-description">
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src="../images/testing.jpg"
                          alt="single-img-eighteen"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="res-767-mt-20">{t("title1")}</h5>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <ul className="ttm-list ttm-list-style-icon mt-20">
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title1Solution1")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title1Solution2")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title1Solution3")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title1Solution4")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <h5>{t("title2")}</h5>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <ul className="ttm-list ttm-list-style-icon mt-20">
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title2Solution1")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title2Solution2")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title2Solution3")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title2Solution4")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper res-767-mt-20">
                        <img
                          className="img-fluid"
                          src="../images/performance.png"
                          alt="single-img-nineteen"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src="../images/mobile-test.jpg"
                          alt="single-img-eighteen"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="res-767-mt-20">{t("title3")}</h5>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <ul className="ttm-list ttm-list-style-icon mt-20">
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title3Solution1")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title3Solution2")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title3Solution3")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <h5>{t("title4")}</h5>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <ul className="ttm-list ttm-list-style-icon mt-20">
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title4Solution1")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title4Solution2")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title4Solution3")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title4Solution4")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title4Solution5")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper res-767-mt-20">
                        <img
                          className="img-fluid"
                          src="../images/uat.png"
                          alt="single-img-nineteen"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src="../images/security-testing.jpg"
                          alt="single-img-eighteen"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="res-767-mt-20">{t("title5")}</h5>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <ul className="ttm-list ttm-list-style-icon mt-20">
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title5Solution1")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title5Solution2")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title5Solution3")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title5Solution4")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("title5Solution5")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                </div>
              </div>
              {/* ttm-service-single-content-are end */}
            </div>
            <div className="col-lg-3 widget-area">
              <Sidebar />
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
}
