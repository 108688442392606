import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Faq() {
  const { t } = useTranslation("faq");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Frequently Asked Questions`}</title>
        <meta name="description" content="Is there anything you want to ask?" />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("title")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("title")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <section className="ttm-row faqs-section clearfix">
        <div className="container-xl">
          <div className="row">
            {/* row */}
            <div className="col-lg-8 offset-lg-2">
              {/* section-title */}
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h5>{t("title")}</h5>
                  <h2 className="title">{t("anyQuestions")}</h2>
                </div>
              </div>
              {/* section-title end */}
            </div>
          </div>
          {/* row end */}
          {/* row */}
          <div className="row">
            <div className="col-12 res-991-mt-30">
              {/* accordian */}
              <Accordion preExpanded={["foo"]}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>{t("faq1")}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{t("faq1Text1")}</p>
                    <p>{t("faq1Text2")}</p>
                    <p>{t("faq1Text3")}</p>
                    <p>{t("faq1Text4")}</p>
                    <p>{t("faq1Text5")}</p>
                    <p>{t("faq1Text6")}</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>{t("faq2")}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{t("faq2Text")}</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>{t("faq3")}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      {t("faq3Text")}
                      <br />
                      <a
                        href={process.env.PUBLIC_URL + "/contracts"}
                        style={{ textDecoration: "underline" }}
                      >
                        <strong style={{ color: "#f16141" }}>
                          {t("faq3TextDetail")}
                        </strong>
                      </a>
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>{t("faq4")}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{t("faq4Text")}</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>{t("faq5")}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{t("faq5Text")}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          {/* row end*/}
        </div>
      </section>
    </div>
  );
}
