import axios from "axios";
import appConfig from "../../config/appConfig";

const sendContactMail = (
  firstName,
  lastName,
  phone,
  email,
  contactType,
  description,
  message
) => {
  return axios.post(appConfig.route("mail/contact"), {
    firstName,
    lastName,
    phone,
    email,
    contactType,
    description,
    message,
  });
};

const sendAppointment = (
  firstName,
  lastName,
  phone,
  email,
  subject,
  date,
  message
) => {
  return axios.post(appConfig.route("mail/appointment"), {
    firstName,
    lastName,
    phone,
    email,
    subject,
    date,
    message,
  });
};

const sendQuotation = (
  file,
  contractType,
  consultancyServices,
  firstName,
  lastName,
  phone,
  email,
  message
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("contractType", contractType);
  formData.append("consultancyServices", consultancyServices);
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("phone", phone);
  formData.append("email", email);
  formData.append("message", message);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(appConfig.route("mail/quotation"), formData, config);
};

const mailService = {
  sendContactMail,
  sendAppointment,
  sendQuotation,
};

export default mailService;
