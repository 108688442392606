import React from "react";
import Sidebar from "../services/Sidebar";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function AiMachineLearningSolutions() {
  const { t } = useTranslation("aiMachineLearningSolutions");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - AI / Machine Learning Solutions`}</title>
        <meta
          name="description"
          content="We provide solutions with artificial intelligence and machine learning for our business partners who want to apply smarter solutions to their current problems. Together, we can turn your problems into opportunities with artificial intelligence and machine learning approaches."
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("aiMachineLearningSolutions")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("aiMachineLearningSolutions")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 content-area order-lg-2">
              {/* ttm-service-single-content-are */}
              {/* ttm-service-single-content-are */}
              <div className="section-title with-desc text-left clearfix">
                <div className="title-header">
                  <p>{t("categoryText")}</p>
                  <h5>{t("aiMlApplication")}</h5>
                  <p>{t("aiMlApplicationText1")}</p>
                  <p>{t("aiMlApplicationText2")}</p>
                  <p>{t("aiMlApplicationText3")}</p>
                  <p className="mb-0">{t("aiMlApplicationText4")}</p>
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                    style={{ width: "100%", textAlign: "center" }}
                    href={
                      process.env.PUBLIC_URL + "/technologies/aiMachineLearning"
                    }
                  >
                    {t("technologies")}
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 widget-area">
              <Sidebar />
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
}
