import _ from "../helpers/@loadash";
import { withFormsy } from "formsy-react";
import React from "react";
import { useTranslation } from "react-i18next";

function TextBox(props) {
  const { t } = useTranslation("formsy");
  const importedProps = _.pick(props, [
    "className",
    "placeholder",
    "name",
    "type",
    "required",
    "pattern",
  ]);

  const { errorMessage, showRequired } = props;
  const value = props.value || "";
  let error = errorMessage;
  const requiredText = t("required");
  if (!error && showRequired) {
    error = requiredText;
  }

  if (importedProps.type === "email" && error && error !== requiredText) {
    error = t("wrongEmailFormat");
  }

  function changeValue(event) {
    props.setValue(event.currentTarget.value);
    if (props.onChange) {
      props.onChange(event.currentTarget.value);
    }
  }

  return (
    <div className={importedProps.className || "col-sm-6 col-md-6"}>
      <div className="form-group">
        <input
          pattern={importedProps.pattern}
          name={importedProps.name}
          type={importedProps.type || "text"}
          placeholder={importedProps.placeholder}
          required={importedProps.required}
          value={value}
          className="form-control bg-white border"
          onChange={changeValue}
        />
        <small>{error}</small>
      </div>
    </div>
  );
}

export default React.memo(withFormsy(TextBox));
