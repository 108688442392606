import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const styles = {
  splashScreen: {
    width: "100%",
    height: "100vh",
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999999,
    backgroundColor: "white",
    minHeight: "100vh",
  },
};

function BusyIndicator(props) {
  const isBusy = useSelector((selector) => {
    return selector.busyIndicator.busyIndicatorState.isBusy;
  });
  const busyContent = useSelector((selector) => {
    return selector.busyIndicator.busyIndicatorState.busyContent;
  });
  if (isBusy) {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
  } else {
    document.body.style.overflow = "unset";
  }

  return (
    isBusy && (
      <div style={styles.splashScreen}>
        <div className="row">
          <div className="col-sm-12">
            <svg
              width="200px"
              height="200px"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                backgroundImage: "url(../images/pre-loader-1.gif)",
              }}
            ></svg>
          </div>
          <div className="col-sm-12">
            <h5 style={{ textAlign: "center" }}>{busyContent}</h5>
          </div>
        </div>
      </div>
    )
  );
}

BusyIndicator.propTypes = {
  isBusy: PropTypes.bool,
  busyContent: PropTypes.string,
  classes: PropTypes.object,
};

export default BusyIndicator;
