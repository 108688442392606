import React from "react";
import { useTranslation } from "react-i18next";

export default function PackageTag(props) {
  const { t } = useTranslation("packageList");
  return (
    <div>
      <div className="separator mt-10">
        <div className="sep-line mb-4" />
      </div>
      {/* separator */}
      <div className="row">
        <div className="col-12">
          <div className="featured-content">
            <div className="featured-title">
              {/* featured title */}
              <h5>{props.title}</h5>
            </div>
            <div className="featured-desc">
              {/* featured desc */}
              <p>{props.description}</p>
            </div>
          </div>
        </div>
        <div className="col-12 mt-20">
          <div className="section-title with-desc text-left clearfix">
            <div className="title-header">
              <h5>{t("hiringOptions")}</h5>
            </div>
          </div>
          {/* featured-icon-box */}
          <div className="featured-icon-box style2 left-icon icon-align-top">
            <div className="featured-icon">
              {/* featured-icon */}
              <div
                style={{ margin: 4 }}
                className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
              >
                <strong>
                  {props.isTeam ? t("hiringOption1") : t("hiringOption5")}
                </strong>
              </div>
            </div>
            <div className="featured-icon">
              <div
                style={{ margin: 4 }}
                className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
              >
                <strong>
                  {props.isTeam ? t("hiringOption2") : t("hiringOption6")}
                </strong>
              </div>
            </div>
            <div className="featured-icon">
              <div
                style={{ margin: 4 }}
                className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
              >
                <strong>
                  {props.isTeam ? t("hiringOption3") : t("hiringOption7")}
                </strong>
              </div>
            </div>
            <div className="featured-icon">
              <div
                style={{ margin: 4 }}
                className="ttm-icon ttm-icon_element-border ttm-textcolor-skincolor ttm-icon_element-size-md"
              >
                <strong>{t("hiringOption4")}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
