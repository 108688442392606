import React from "react";
import Sidebar from "../services/Sidebar";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function NoSqlRelationalSolutions() {
  const { t } = useTranslation("noSqlRelationalSolutions");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - NoSQL / Relational Database Solutions`}</title>
        <meta
          name="description"
          content={`With our database experts, you can solve your complex business problems with relational database management systems such as PostgreSQl, Oracle, MySQL and SQL Server or MongoDB, Couchbase, Apache Cassandra or other NoSQL databases.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("noSqlRelationalSolutions")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("noSqlRelationalSolutions")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 content-area order-lg-2">
              {/* ttm-service-single-content-are */}
              {/* ttm-service-single-content-are */}
              <div className="ttm-service-single-content-area">
                {/* section title */}
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <p>{t("categoryText")}</p>
                    <h5>{t("databaseConsultancy")}</h5>
                    <p>{t("databaseConsultancyText1")}</p>
                    <p>{t("databaseConsultancyText2")}</p>
                    <p>{t("databaseConsultancyText3")}</p>
                    <a
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                      style={{ width: "100%", textAlign: "center" }}
                      href={process.env.PUBLIC_URL + "/technologies/database"}
                    >
                      {t("technologies")}
                    </a>
                  </div>
                </div>
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h2 className="title">{t("databaseConsultingServices")}</h2>
                  </div>
                </div>
                {/* section title end */}
                <div className="ttm_single_image-wrapper mb-35">
                  <img
                    className="img-fluid"
                    src="../images/database.jpg"
                    alt="Database"
                  />
                </div>
                <div className="ttm-service-description">
                  <h3>{t("relationalDatabase")}</h3>
                  <p>{t("relationalDatabaseText1")}</p>
                  <p>{t("relationalDatabaseText2")}</p>
                  <p>{t("relationalDatabaseText3")}</p>
                  <p> {t("relationalDatabaseText4")}</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src="../images/sql.png"
                          alt="SQL"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="res-767-mt-20">
                        {t("relationalDatabaseIssue1")}
                      </h5>
                      <p>{t("relationalDatabaseIssue1Text")}</p>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <h5>{t("relationalDatabaseIssue2")}</h5>
                      <p>{t("relationalDatabaseIssue2Text")}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper res-767-mt-20">
                        <img
                          className="img-fluid"
                          src="../images/data.jpg"
                          alt="Data Integrity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src="../images/transactions.jpg"
                          alt="Transactions"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="res-767-mt-20">
                        {t("relationalDatabaseIssue3")}
                      </h5>
                      <p>{t("relationalDatabaseIssue3Text")}</p>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-md-6">
                      <h5>{t("relationalDatabaseIssue4")}</h5>
                      <p>{t("relationalDatabaseIssue4Text")}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper res-767-mt-20">
                        <img
                          className="img-fluid"
                          src="../images/big-data.jpg"
                          alt="ACID"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="separator">
                    <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                  </div>
                  <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src="../images/nosql.png"
                      alt="NoSQL"
                    />
                  </div>
                  <h3>{t("noSqlDatabase")}</h3>
                  <p>{t("noSqlDatabaseText1")}</p>
                  <p>{t("noSqlDatabaseText2")}</p>
                  <p>{t("noSqlDatabaseText3")}</p>
                </div>
              </div>
              {/* ttm-service-single-content-are end */}
            </div>
            <div className="col-lg-3 widget-area">
              <Sidebar />
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
}
