import React from "react";
import Slider from "react-slick";
import { Header } from "./layout/Header";
import CarouselPage from "./banner/CarouselPage";
import "react-image-lightbox/style.css";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation("home");
  var slick_slider = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="site-main">
      <Header />
      {/* home banner */}
      <CarouselPage />
      {/* home banner end*/}
      {/* service-section */}
      <section className="ttm-row service-section position-relative z-1 clearfix">
        <div className="container-xl">
          <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
            <div className="col-md-4">
              <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
                <div className="featured-icon">
                  {/*  featured-icon */}
                  <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                    <i className="flaticon flaticon-system" />
                    {/*  ttm-icon */}
                  </div>
                </div>
                <div className="featured-content">
                  {/*  featured-content */}
                  <div className="featured-title">
                    {/*  featured-title */}
                    <h5>{t("realTimeCommunication")}</h5>
                  </div>
                  <div className="featured-desc">
                    {/*  featured-desc */}
                    <p>{t("realTimeCommunicationText")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {/*  featured-icon-box */}
              <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white">
                <div className="featured-icon">
                  {/*  featured-icon */}
                  <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
                    <i className="flaticon flaticon-clock" />
                    {/*  ttm-icon */}
                  </div>
                </div>
                <div className="featured-content">
                  {/*  featured-content */}
                  <div className="featured-title">
                    {/*  featured-title */}
                    <h5>{t("support7-24")}</h5>
                  </div>
                  <div className="featured-desc">
                    {/*  featured-desc */}
                    <p>{t("phone")}</p>
                  </div>
                </div>
              </div>
              {/*  featured-icon-box END */}
            </div>
            <div className="col-md-4">
              {/*  featured-icon-box */}
              <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                <div className="featured-icon">
                  {/*  featured-icon */}
                  <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                    <i className="flaticon flaticon-developer" />
                    {/*  ttm-icon */}
                  </div>
                </div>
                <div className="featured-content">
                  {/*  featured-content */}
                  <div className="featured-title">
                    {/*  featured-title */}
                    <h5>{t("scalableBusinessModel")}</h5>
                  </div>
                  <div className="featured-desc">
                    {/*  featured-desc */}
                    <p>{t("scalableBusinessModelText")}</p>
                  </div>
                </div>
              </div>
              {/*  featured-icon-box END */}
            </div>
          </div>
        </div>
      </section>
      {/* service-section end */}
      {/* aboutus-section */}
      <section className="ttm-row aboutus-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-6">
              {/* section title */}
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h5>{t("aboutus")}</h5>
                  <h2 className="title">{t("motto1")}</h2>
                </div>
                <div className="title-desc">
                  <p>{t("companyInfo1")}</p>
                  <p>{t("companyInfo2")}</p>
                </div>
              </div>
              {/* section title end */}
              {/* row */}
              <div className="row no-gutters mt-4">
                <div className="col-md-6 col-lg-6 col-sm-6">
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category1")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category2")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category3")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category4")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category5")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category6")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category7")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category8")}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6">
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category9")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category10")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category11")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category12")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category13")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category14")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category15")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category16")}
                      </span>
                    </li>
                    <li>
                      <i className="far fa-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category17")}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* row END*/}
              {/* separator */}
              <div className="separator">
                <div className="sep-line mt-4 mb-4" />
              </div>
              {/* separator */}
              <div className="fs-16 ttm-textcolor-darkgrey">
                <span>
                  <a
                    className="fw-500 ttm-textcolor-darkgrey"
                    href={process.env.PUBLIC_URL + "/faq"}
                  >
                    <u>{t("faq")}</u>
                  </a>{" "}
                </span>
                <strong className="fs-18">: +90 850 441 81 10</strong>
              </div>
            </div>
            <div className="col-lg-6 res-991-mt-40 res-991-text-center">
              <div className="position-relative res-991-mt-30">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper">
                  <img
                    className="img-fluid"
                    src="../images/software.jpg"
                    title="software development"
                    alt="software development"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
                {/*featured-icon-box*/}
                {/* featured-icon-box end*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* aboutus-section end */}
      {/* history-section */}
      <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {/* section title */}
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h5>{t("ourServices")}</h5>
                  <h2 className="title">{t("motto2")}</h2>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>
          <Slider
            className="row slick_slider ttm-boxes-spacing-30px"
            {...slick_slider}
          >
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-code" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category1")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category1Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/technologies/all"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-business-and-finance" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category2")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category2Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/softwareConsultancy"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-computer" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category3")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category3Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/webDesktopDevelopment"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-mobile-app" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category4")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category4Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/mobileDevelopment"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-graphic-design" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category5")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category5Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/uiUxDesign"}
                  >
                    {t("readMore")}
                    <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-system" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category6")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category6Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/devOpsSecOpsSupport"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-data" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category7")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category7Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={
                      process.env.PUBLIC_URL + "/aiMachineLearningSolutions"
                    }
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-global-1" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category8")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category8Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/blockChainSolutions"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-24h" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category9")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category9Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/packageList"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-server" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category10")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category10Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/noSqlRelationalSolutions"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-computer-1" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category11")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category11Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/technicalSupport"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-analysis" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category12")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category12Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/baSupport"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-interaction" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category13")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category13Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/testSupport"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-padlock" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category14")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category14Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/eTransformation"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-global" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category15")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category15Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/agileTransformation"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-content-writing" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category16")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category16Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/grantSupportConsultancy"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">
                {/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">
                  {/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-resume" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />
                {/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title">
                    <h5>{t("category17")}</h5>
                  </div>
                  {/* historybox-title  */}
                  <div className="ttm-historybox-description">
                    {/* description  */}
                    {t("category17Text")}
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    href={process.env.PUBLIC_URL + "/expertiseServices"}
                  >
                    {t("readMore")} <i className="ti ti-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
          </Slider>

          <div className="row">
            <div className="col-md-12 text-center mt-5 res-991-mt-30">
              <p className="mb-0">
                {t("contactUs")}
                <strong>
                  <u>
                    <a
                      className="ttm-textcolor-darkgrey"
                      href={process.env.PUBLIC_URL + "/ourServices"}
                    >
                      {" "}
                      {t("ourServices")}
                    </a>
                  </u>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* history-section end*/}
      {/* zero-padding-section */}
      <section className="ttm-row zero-padding-section mt_80 res-991-mt-0 clearfix">
        <div className="container-xl">
          <div className="row no-gutters">
            <div className="col-lg-6">
              {/* col-bg-img-one */}
              <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes ttm-left-span res-991-h-auto">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>
              </div>
              {/* Responsive View image */}
              <img
                src="../images/colleagues-giving-fist-bump.jpg"
                className="ttm-equal-height-image img-fluid"
                alt="runic bytes"
              />
            </div>
            <div className="col-lg-6 z-9">
              <div className="ttm-bgcolor-skincolor ttm-textcolor-white position-relative ttm-right-span">
                <div className="spacing-1">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h5>{t("whyUs")}</h5>
                      <h2 className="title">{t("motto3")}</h2>
                    </div>
                    <div className="title-desc">
                      <p>{t("whyUsText")}</p>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="row mt-40">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-process" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>{t("productDesigns")}</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>{t("productDesignsText")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                  <div className="separator">
                    <div className="sep-line mt-4 mb-4" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-system" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>{t("digitalSolutions")}</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>{t("digitalSolutionsText")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                  <div className="separator">
                    <div className="sep-line mt-4 mb-4" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-data" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>{t("systemIntegration")}</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>{t("systemIntegrationText")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* zero-padding-section end */}
      {/* team-section */}
      <section className="ttm-row team-section res-991-pt-0 clearfix">
        <div className="container-xl">
          <div className="row align-items-center mb-5">
            <div className="col-lg-5 res-991-text-center">
              <div className="section-title style2 mb-0 clearfix">
                <div className="title-header mb-0"></div>
              </div>
            </div>
            <div className="col-lg-5 res-991-text-center"></div>
            <div className="col-lg-2 text-right res-991-text-center res-991-mt-20"></div>
          </div>
        </div>
      </section>
      {/* team-section end*/}
      {/* first-row-title-section */}
    </div>
  );
}
