import React from "react";
import Sidebar from "../services/Sidebar";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function SoftwareConsultancy() {
  const { t } = useTranslation("softwareConsultancy");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Software Consultancy`}</title>
        <meta
          name="description"
          content={`Activities such as projecting customer needs, determining project requirements and implementing service processes; We offer you consultancy services on software development processes, new technologies, and how project management processes should be.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("softwareConsultancy")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("softwareConsultancy")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 content-area order-lg-2">
              {/* ttm-service-single-content-are */}
              {/* ttm-service-single-content-are */}
              <div className="ttm-service-single-content-area">
                {/* section title */}
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <p>{t("intro")}</p>
                    <h5>{t("whySoftwareConsultancy")}</h5>
                    <p>{t("whySoftwareConsultancyText1")}</p>
                    <p>{t("whySoftwareConsultancyText2")}</p>
                    <p>{t("whySoftwareConsultancyText3")}</p>
                    <div className="featured-desc">
                      {/* featured-desc */}
                      <ul className="ttm-list ttm-list-style-icon mt-20">
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("whySoftwareConsultancyIssue1")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("whySoftwareConsultancyIssue2")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("whySoftwareConsultancyIssue3")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("whySoftwareConsultancyIssue4")}
                          </span>
                        </li>
                        <li>
                          <i className="ti-check-box ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            {t("whySoftwareConsultancyIssue5")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <a
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                      style={{ width: "100%", textAlign: "center" }}
                      href={process.env.PUBLIC_URL + "/technologies/all"}
                    >
                      {t("technologies")}
                    </a>
                  </div>
                </div>
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h2 className="title">{t("advisoryServices")}</h2>
                  </div>
                </div>
                {/* section title end */}
                <div className="ttm_single_image-wrapper mb-35">
                  <img
                    className="img-fluid"
                    src="../images/ideas.jpg"
                    alt="ideas"
                  />
                </div>
                <div className="ttm-service-description">
                  <h3>{t("advisoryServices1")}</h3>
                  <p>{t("advisoryServices1Text1")}</p>
                  <p>{t("advisoryServices1Text2")}</p>
                  <div className="featured-desc">
                    {/* featured-desc */}
                    <ul className="ttm-list ttm-list-style-icon mt-20">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("advisoryServices1Issue1")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("advisoryServices1Issue2")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("advisoryServices1Issue3")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="separator">
                    <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                  </div>
                  <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src="../images/roi.jpg"
                      alt="ROI"
                    />
                  </div>
                  <h3>{t("advisoryServices2")}</h3>
                  <p>{t("advisoryServicesText1")}</p>
                  <p>{t("advisoryServicesText2")}</p>
                  <div className="featured-desc">
                    {/* featured-desc */}
                    <ul className="ttm-list ttm-list-style-icon mt-20">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("advisoryServices2Issue1")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("advisoryServices2Issue2")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("advisoryServices2Issue3")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("advisoryServices2Issue4")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* separator */}
                  <div className="separator">
                    <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                  </div>
                  <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src="../images/programmer.jpg"
                      alt="Architecture"
                    />
                  </div>
                  <h3>{t("advisoryServices3")}</h3>
                  <p>{t("advisoryServices3Text1")}</p>
                  <p>{t("advisoryServices3Text2")}</p>
                  <p>{t("advisoryServices3Text3")}</p>
                  <p>{t("advisoryServices3Text4")}</p>
                  <div className="featured-desc">
                    {/* featured-desc */}
                    <ul className="ttm-list ttm-list-style-icon mt-20">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("advisoryServices3Issue1")}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {t("advisoryServices3Issue2")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* separator */}
                  <div className="separator">
                    <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-md-6">
                      <h3>{t("advisoryServices4")}</h3>
                      <p>{t("advisoryServices4Text1")}</p>
                      <p>{t("advisoryServices4Text2")}</p>
                      <a
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                        href={process.env.PUBLIC_URL + "/technologies/all"}
                      >
                        {t("technologies")}
                      </a>
                    </div>
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper res-767-mt-20">
                        <img
                          className="img-fluid"
                          src="../images/developer.jpg"
                          alt="Development"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ttm-service-single-content-are end */}
            </div>
            <div className="col-lg-3 widget-area">
              <Sidebar />
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
}
