import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function OurValues() {
  const { t } = useTranslation("ourValues");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Our Values`}</title>
        <meta
          name="description"
          content={`Core values are the essence of a company. As Runic Bytes, we exist with the following core values that make us who we are;`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("ourValues")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("ourValues")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      {/* about-services-section */}
      <section className="ttm-row about-services-section clearfix">
        <div className="container-xl">
          <div className="row align-items-center">
            {/* row */}
            <div className="col-lg-12">
              <div className="res-991-mt-30">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>{t("ourValues")}</h5>
                    <h2 className="title">{t("coreValues")}</h2>
                  </div>
                  <div className="title-desc">
                    <p>{t("coreValuesText1")}</p>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue1")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue1Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue2")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue2Text1")}</p>
                        <p>{t("coreValue2Text2")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue3")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue3Text")}</p>
                        <ul className="ttm-list ttm-list-style-icon mt-20">
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("coreValue3Rule1")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("coreValue3Rule2")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("coreValue3Rule3")}
                            </span>
                          </li>
                          <li>
                            <i className="ti-check-box ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              {t("coreValue3Rule4")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue4")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue4Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue5")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue5Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue6")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue6Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue7")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue7Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue8")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue8Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue9")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue9Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue10")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue10Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <h5>{t("coreValue11")}</h5>
                        </div>
                      </div>
                      <div className="featured-desc">
                        {/* featured-desc */}
                        <p>{t("coreValue11Text")}</p>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                {/* separator */}
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* about-services-section end */}
      {/* services-slide-section */}
    </div>
  );
}
