import React from "react";
import Sidebar from "../services/Sidebar";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function MobileDevelopment() {
  const { t } = useTranslation("mobileDevelopment");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Mobile App Development`}</title>
        <meta
          name="description"
          content={`We provide both native and non-native mobile application development services for Android and IOS.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("mobileDevelopment")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("mobileDevelopment")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 content-area order-lg-2">
              {/* ttm-service-single-content-are */}
              {/* ttm-service-single-content-are */}
              <div className="section-title with-desc text-left clearfix">
                <div className="title-header">
                  <p>{t("categoryText")}</p>
                  <h5>{t("mobileDevelopmentServices")}</h5>
                  <p>{t("mobileDevelopmentServicesText")}</p>
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                    style={{ width: "100%", textAlign: "center" }}
                    href={
                      process.env.PUBLIC_URL + "/technologies/mobileDevelopment"
                    }
                  >
                    {t("technologies")}
                  </a>
                </div>
              </div>
              <div className="ttm-service-single-content-area">
                <div className="ttm-service-description">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src="../images/mobile.jpg"
                          alt="single-img-eighteen"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="res-767-mt-20">
                        {t("mobileDevelopment")}
                      </h3>
                      <p>{t("mobileDevelopmentText1")}</p>
                      <p>{t("mobileDevelopmentText2")}</p>
                    </div>
                  </div>
                  {/* separator */}
                </div>
              </div>
              {/* ttm-service-single-content-are end */}
            </div>

            <div className="col-lg-3 widget-area">
              <Sidebar />
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
}
