import React, { useState, useRef, useEffect } from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import Formsy from "formsy-react";
import TextBox from "../formsy/textbox";
import SelectBox from "../formsy/selectbox";
import TextArea from "../formsy/textarea";
import PhoneBox from "../formsy/phonebox";
import { validatePhoneNumber } from "../helpers/phoneValidator";
import {
  sendContactMail,
  resetContactMail,
} from "../../modules/contact/actions";
import { openModal } from "../../modules/modal/actions";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, clearBusy } from "../../modules/busyIndicator/actions";
import { Helmet } from "react-helmet";

export default function Contact() {
  const { t } = useTranslation("contact");
  const [contactType, setContactType] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const error = useSelector((state) => state.contact.error);
  const success = useSelector((state) => state.contact.success);
  const formRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      dispatch(clearBusy());
      dispatch(resetContactMail());
      dispatch(
        openModal({
          title: "E-Posta Gönderimi",
          message: "E-Posta başarılı bir şekilde gönderilmiştir.",
          onOk: () => {
            formRef.current.reset();
          },
          onCancel: () => {
            formRef.current.reset();
          },
        })
      );
    } else if (error) {
      dispatch(clearBusy());
      dispatch(
        openModal({
          title: "E-Posta Gönderimi",
          message: error,
        })
      );
      dispatch(resetContactMail());
    }
  });

  function disableButton() {
    setCanSubmit(false);
  }

  function enableButton() {
    setCanSubmit(true);
  }

  function submit(model) {
    dispatch(setBusy("E-Posta gönderiliyor."));
    dispatch(sendContactMail(model));
  }

  let submitClassName = "mt-3 ttm-btn ttm-btn-size-md";
  if (canSubmit) {
    submitClassName += " ttm-bgcolor-skincolor";
  }
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Contact`}</title>
        <meta
          name="description"
          content="info@runicbytes.com | +90 850 441 81 10 | Ankara Teknopark TGB Yerleşkesi İvedik OSB Mahallesi 2224. Cad. No:1 İç Kapı No:116 B Blok Üst Zemin Kat Yenimahalle 06378 ANKARA TÜRKİYE"
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("contact")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("contact")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <section className="ttm-row zero-padding-section clearfix">
        <div className="container-xl">
          <div className="row no-gutters">
            {/* row */}
            <div className="col-lg-5">
              <div className="spacing-9">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>{t("visitUs")}</h5>
                    <h2 className="title">{t("ourAddress")}</h2>
                  </div>
                </div>
                {/* section title end */}
                <div className="row">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="ti-location-pin" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          {/* featured title */}
                          <h5>{t("officeAddress")}</h5>
                        </div>
                        <div className="featured-desc">
                          {/* featured desc */}
                          <p>{t("address")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-4" />
                </div>
                {/* separator */}
                <div className="row">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          {/* featured title */}
                          <h5>{t("phoneNumber")}</h5>
                        </div>
                        <div className="featured-desc">
                          {/* featured desc */}
                          <p>{t("phone")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-4" />
                </div>
                {/* separator */}
                <div className="row">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="ti ti-email" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          {/* featured title */}
                          <h5>{t("mail")}</h5>
                        </div>
                        <div className="featured-desc">
                          {/* featured desc */}
                          <p>info@runicbytes.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>{t("sendMessage")}</h5>
                    <h2 className="title">{t("writeToUs")}</h2>
                  </div>
                </div>
                {/* section title end */}
                <Formsy
                  ref={formRef}
                  onValidSubmit={submit}
                  onValid={enableButton}
                  onInvalid={disableButton}
                  id="ttm-quote-form"
                  className="row ttm-quote-form clearfix"
                >
                  <TextBox
                    name="firstName"
                    type="text"
                    className="col-sm-6 col-md-6"
                    placeholder={t("firstName")}
                    required
                    validations={{
                      maxLength: 30,
                    }}
                    validationErrors={{
                      maxLength: t("firstNameMaxLength"),
                    }}
                  ></TextBox>
                  <TextBox
                    name="lastName"
                    type="text"
                    className="col-sm-6 col-md-6"
                    placeholder={t("lastName")}
                    required
                    validations={{
                      maxLength: 30,
                    }}
                    validationErrors={{
                      maxLength: t("lastNameMaxLength"),
                    }}
                  ></TextBox>
                  <PhoneBox
                    name="phone"
                    placeholder={t("phoneNumber2")}
                    className="col-sm-6 col-md-6"
                    required
                    validations={{
                      isValidPhoneNumberValidation: function (values, value) {
                        return validatePhoneNumber(values, value, true);
                      },
                    }}
                  ></PhoneBox>
                  <TextBox
                    name="email"
                    type="email"
                    className="col-sm-6 col-md-6"
                    placeholder={t("mail2")}
                    required
                    validations="isEmail"
                    validationError={t("wrongEmailFormat")}
                  ></TextBox>
                  <SelectBox
                    className="col-sm-12 col-md-12"
                    required
                    placeholder={t("contactReason")}
                    name="contactType"
                    onChange={setContactType}
                    options={[
                      { key: "1", value: t("option1") },
                      { key: "2", value: t("option2") },
                      { key: "3", value: t("option3") },
                      { key: "4", value: t("option4") },
                      { key: "5", value: t("option5") },
                      { key: "6", value: t("option6") },
                      { key: "7", value: t("option7") },
                      { key: "8", value: t("option8") },
                    ]}
                  ></SelectBox>
                  {contactType === "8" ? (
                    <TextBox
                      name="description"
                      type="text"
                      className="col-sm-12 col-md-12"
                      placeholder={t("otherDescription")}
                      required
                      validations={{
                        maxLength: 250,
                        minLength: 10,
                      }}
                      validationErrors={{
                        maxLength: t("descriptionMaxLength"),
                        minLength: t("descriptionMinLength"),
                      }}
                    ></TextBox>
                  ) : (
                    ""
                  )}
                  <TextArea
                    name="message"
                    rows={5}
                    placeholder={t("writeAMessage")}
                    required
                    validations={{
                      maxLength: 1000,
                      minLength: 10,
                    }}
                    validationErrors={{
                      maxLength: t("messageMaxLength"),
                      minLength: t("messageMinLength"),
                    }}
                  ></TextArea>

                  <div className="col-md-12">
                    <div className="text-left">
                      <button
                        type="submit"
                        id="submit"
                        className={submitClassName}
                        disabled={!canSubmit}
                      >
                        {t("send")}
                      </button>
                    </div>
                  </div>
                </Formsy>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      <section className="ttm-row map-section res-991-p-0 clearfix">
        <div className="map-wrapper">
          <iframe
            title="Runic Bytes"
            height="100%"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d454.36350837479483!2d32.752078445596865!3d39.99546434666472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34f67a40d6911%3A0xab8d93ab9d472ca!2sRunic%20Bytes!5e0!3m2!1str!2str!4v1656152794024!5m2!1str!2str"
          />
        </div>
      </section>
    </div>
  );
}
