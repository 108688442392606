import React, { useState, useEffect, useRef } from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import Formsy from "formsy-react";
import TextBox from "../formsy/textbox";
import TextArea from "../formsy/textarea";
import PhoneBox from "../formsy/phonebox";
import DateTimePicker from "../formsy/datetimepicker";
import { validatePhoneNumber } from "../helpers/phoneValidator";
import {
  sendAppointmentMail,
  resetAppointmentMail,
} from "../../modules/appointment/actions";
import { openModal } from "../../modules/modal/actions";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, clearBusy } from "../../modules/busyIndicator/actions";
import { Helmet } from "react-helmet";

export default function Appointment() {
  const { t } = useTranslation("makeAppointment");
  const [canSubmit, setCanSubmit] = useState(false);
  const [value, setValue] = useState(null);
  const error = useSelector((state) => state.appointment.error);
  const success = useSelector((state) => state.appointment.success);
  const formRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      dispatch(clearBusy());
      dispatch(resetAppointmentMail());
      dispatch(
        openModal({
          title: "E-Posta Gönderimi",
          message: "E-Posta başarılı bir şekilde gönderilmiştir.",
          onOk: () => {
            formRef.current.reset();
          },
          onCancel: () => {
            formRef.current.reset();
          },
        })
      );
      setValue(null);
    } else if (error) {
      dispatch(clearBusy());
      dispatch(
        openModal({
          title: "E-Posta Gönderimi",
          message: error,
        })
      );
      dispatch(resetAppointmentMail());
      setValue(null);
    }
  });
  function disableButton() {
    setCanSubmit(false);
  }

  function enableButton() {
    setCanSubmit(true);
  }

  function submit(model) {
    dispatch(setBusy("E-Posta gönderiliyor."));
    dispatch(sendAppointmentMail(model));
  }

  let submitClassName = "mt-3 ttm-btn ttm-btn-size-md";
  if (canSubmit) {
    submitClassName += " ttm-bgcolor-skincolor";
  }

  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Make An Appointment`}</title>
        <meta
          name="description"
          content="info@runicbytes.com | +90 850 441 81 10 | Ankara Teknopark TGB Yerleşkesi İvedik OSB Mahallesi 2224. Cad. No:1 İç Kapı No:116 B Blok Üst Zemin Kat Yenimahalle 06378 ANKARA TÜRKİYE"
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("makeAppointment")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/Single_blog"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("makeAppointment")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <section className="ttm-row contact-section clearfix">
        <div className="container-xl">
          <div className="row">
            {/* row */}
            <div className="col-lg-8 offset-lg-2">
              {/* section-title */}
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h5>{t("contact")}</h5>
                  <h2 className="title">{t("makeAppointment")}</h2>
                </div>
              </div>
              {/* section-title end */}
            </div>
          </div>
          {/* row end */}
          {/* row */}
          <Formsy
            ref={formRef}
            onValidSubmit={submit}
            onValid={enableButton}
            onInvalid={disableButton}
            id="ttm-quote-form"
            className="row ttm-quote-form clearfix"
          >
            <TextBox
              name="firstName"
              type="text"
              className="col-sm-6 col-md-6"
              placeholder={t("firstName")}
              required
              validations={{
                maxLength: 30,
              }}
              validationErrors={{
                maxLength: t("firstNameMaxLength"),
              }}
            ></TextBox>
            <TextBox
              name="lastName"
              type="text"
              className="col-sm-6 col-md-6"
              placeholder={t("lastName")}
              required
              validations={{
                maxLength: 30,
              }}
              validationErrors={{
                maxLength: t("lastNameMaxLength"),
              }}
            ></TextBox>
            <PhoneBox
              name="phone"
              placeholder={t("phoneNumber2")}
              className="col-sm-6 col-md-6"
              required
              validations={{
                isValidPhoneNumberValidation: function (values, value) {
                  return validatePhoneNumber(values, value, true);
                },
              }}
            ></PhoneBox>
            <TextBox
              name="email"
              type="email"
              className="col-sm-6 col-md-6"
              placeholder={t("mail2")}
              required
              validationError={t("wrongEmailFormat")}
              validations="isEmail"
            ></TextBox>
            <TextBox
              name="subject"
              type="text"
              className="col-sm-6 col-md-6"
              placeholder={t("subject")}
              required
              validations={{
                maxLength: 100,
                minLength: 5,
              }}
              validationErrors={{
                maxLength: t("subjectMaxLength"),
                minLength: t("subjectMinLength"),
              }}
            ></TextBox>
            <DateTimePicker
              name="date"
              className="col-sm-6 col-md-6"
              required
              value={value}
              minDate={new Date()}
            />
            <TextArea
              name="message"
              rows={5}
              placeholder={t("writeAMessage")}
              required
              validations={{
                maxLength: 1000,
                minLength: 30,
              }}
              validationErrors={{
                maxLength: t("messageMaxLength"),
                minLength: t("messageMinLength"),
              }}
            ></TextArea>

            <div className="col-md-12">
              <div className="text-center">
                <button
                  type="submit"
                  id="submit"
                  className={submitClassName}
                  disabled={!canSubmit}
                >
                  {t("makeAppointment")}
                </button>
              </div>
            </div>
          </Formsy>
          {/* row end*/}
        </div>
      </section>
      {/* contact-box-section */}
      <div className="ttm-row contact-box-section ttm-bgcolor-grey clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-4 res-991-mb-60 res-991-mt-30">
              {/* featured-icon-box */}
              <div className="featured-icon-box style8 text-center box-shadow">
                <div className="featured-icon">
                  {/* featured-icon*/}
                  <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md rounded-circle">
                    <i className="far fa-envelope" />
                  </div>
                </div>
                {/* featured-icon */}
                <div className="featured-content">
                  {/* featured-content */}
                  <div className="featured-title">
                    <h5>{t("email")}</h5>
                    {/* featured-title */}
                  </div>
                  <div className="featured-desc">
                    {/* featured-description */}
                    <p>
                      <a href="mailto:info@example.com">info@runicbytes.com</a>
                      <br />
                    </p>
                  </div>
                </div>
                {/* featured-content END*/}
              </div>
              {/* featured-icon-box */}
            </div>
            <div className="col-lg-4 res-991-mb-60">
              {/* featured-icon-box */}
              <div className="featured-icon-box style8 text-center box-shadow">
                <div className="featured-icon">
                  {/* featured-icon*/}
                  <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md rounded-circle">
                    <i className="fa fa-phone" />
                  </div>
                </div>
                {/* featured-icon */}
                <div className="featured-content">
                  {/* featured-content */}
                  <div className="featured-title">
                    <h5>{t("phoneNumber")}</h5>
                    {/* featured-title */}
                  </div>
                  <div className="featured-desc">
                    {/* featured-description */}
                    <p>
                      {t("phone")}
                      <br />
                    </p>
                  </div>
                </div>
                {/* featured-content END*/}
              </div>
              {/* featured-icon-box */}
            </div>
            <div className="col-lg-4">
              {/* featured-icon-box */}
              <div className="featured-icon-box style8 text-center box-shadow">
                <div className="featured-icon">
                  {/* featured-icon*/}
                  <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md rounded-circle">
                    <i className="ti ti-target" />
                  </div>
                </div>
                {/* featured-icon */}
                <div className="featured-content">
                  {/* featured-content */}
                  <div className="featured-title">
                    <h5>{t("address")}</h5>
                    {/* featured-title */}
                  </div>
                  <div className="featured-desc">
                    {/* featured-description */}
                    <p>
                      {t("addressDetail")}
                      <br />
                    </p>
                  </div>
                </div>
                {/* featured-content END*/}
              </div>
              {/* featured-icon-box */}
            </div>
          </div>
        </div>
      </div>
      {/* contact-box-section end */}
      {/* map-section */}
      <section className="ttm-row map-section clearfix">
        <div className="map-wrapper">
          <iframe
            title="Runic Bytes"
            height="100%"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d454.36350837479483!2d32.752078445596865!3d39.99546434666472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34f67a40d6911%3A0xab8d93ab9d472ca!2sRunic%20Bytes!5e0!3m2!1str!2str!4v1656152794024!5m2!1str!2str"
          />
        </div>
      </section>
      {/* map-section end */}
    </div>
  );
}
