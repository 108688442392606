export const SET_BUSY = "SET_BUSY";
export const CLEAR_BUSY = "CLEAR_BUSY";

export function clearBusy() {
  return {
    type: CLEAR_BUSY,
  };
}

export function setBusy(message) {
  return {
    type: SET_BUSY,
    message,
  };
}
