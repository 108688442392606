import React from "react";
import Sidebar from "../services/Sidebar";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function GrantSupportConsultancy() {
  const { t } = useTranslation("grantSupportConsultancy");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Grant And Support Consultancy`}</title>
        <meta
          name="description"
          content="We can help you design a new grant program or improve an existing one, with professional consultancy support throughout the entire process, from the delivery of the project files to the processes that need to be done during the project execution phase."
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("grantSupportConsultancy")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("grantSupportConsultancy")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 content-area order-lg-2">
              {/* ttm-service-single-content-are */}
              {/* ttm-service-single-content-are */}
              <div className="ttm-service-single-content-area">
                {/* section title */}
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <p>{t("categoryText")}</p>
                    <h5>{t("title")}</h5>
                    <p>{t("titleText1")}</p>
                    <p>{t("titleText2")}</p>
                    <p>{t("titleText3")}</p>
                  </div>
                </div>
              </div>
              {/* ttm-service-single-content-are end */}
            </div>
            <div className="col-lg-3 widget-area">
              <Sidebar />
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
}
