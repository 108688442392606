import React from "react";
import { useTranslation } from "react-i18next";

export default function TechnologyTag(props) {
  const { t } = useTranslation();
  return (
    <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style2">
        <div className="featured-thumbnail">
          <img
            className="img-fluid"
            style={{ height: 250 }}
            src={`../images/${t(props.image)}`}
            alt={t(props.text)}
          />
        </div>
        <div className="featured-content">
          <div className="category">
            <p>
              <strong>{t(props.title)}</strong>
            </p>
          </div>
          <div className="category">
            <p>{t(props.subtitle)}</p>
          </div>
          <div className="featured-title">
            <h5>
              {props.isLink ? (
                <a
                  href={process.env.PUBLIC_URL + t(props.href)}
                  style={{ textDecoration: "underline" }}
                >
                  {t(props.text)}
                </a>
              ) : (
                <p style={{ color: "#ffffff" }}>{t(props.text)}</p>
              )}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
