import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import appConfig from "../config/appConfig";

import rootReducer from "./rootReducer";

const middlewares = [thunkMiddleware];
if (!appConfig.isProduction) {
  const loggerMiddleware = createLogger();
  middlewares.push(loggerMiddleware);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
