import mailService from "../../services/mail/mailService";

export const SEND_CONTACT_MAIL_SUCCESS = "SEND_CONTACT_MAIL_SUCCESS";
export const SEND_CONTACT_MAIL_ERROR = "SEND_CONTACT_MAIL_ERROR";
export const RESET_CONTACT_MAIL = "RESET_CONTACT_MAIL";

export const resetContactMail = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CONTACT_MAIL,
    });
  };
};

const sendContactMailSuccess = () => {
  return {
    type: SEND_CONTACT_MAIL_SUCCESS,
  };
};

const sendContactMailError = (error) => {
  return {
    type: SEND_CONTACT_MAIL_ERROR,
    error,
  };
};

export const sendContactMail = (model) => {
  return (dispatch) => {
    mailService
      .sendContactMail(
        model.firstName,
        model.lastName,
        model.phone,
        model.email,
        model.contactType,
        model.description,
        model.message
      )
      .then((response) => {
        response.data.error
          ? dispatch(sendContactMailError(response.data.error.error))
          : dispatch(sendContactMailSuccess());
      })
      .catch((response) => {
        dispatch(sendContactMailError(response.error));
      });
  };
};
