import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const configureLocalization = () => {
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      supportedLngs: ["en-US", "tr-TR", "de-DE"],
      fallbackLng: "en-US",
      detection: {
        order: [
          "querystring",
          "cookie",
          "localStorage",
          "sessionStorage",
          "navigator",
          "path",
          "subdomain",
          "htmlTag",
        ],
        caches: ["cookie"],
      },
      ns: [
        "footer",
        "header",
        "menu",
        "carousel",
        "home",
        "aboutus",
        "companyInfo",
        "missionVision",
        "ourPolicies",
        "ourValues",
        "ourWayOfWorking",
        "whyUs",
        "contracts",
        "packageList",
        "faq",
        "sidebar",
        "ourServices",
        "softwareConsultancy",
        "webDesktopDevelopment",
        "mobileDevelopment",
        "uiUxDesign",
        "devOpsSecOpsSupport",
        "aiMachineLearningSolutions",
        "blockChainSolutions",
        "noSqlRelationalSolutions",
        "technicalSupport",
        "testSupport",
        "eTransformation",
        "agileTransformation",
        "grantSupportConsultancy",
        "pageNotFound",
        "expertiseServices",
        "freeConsulting",
        "technologies",
        "ourProducts",
        "smartBytes",
        "contact",
        "formsy",
        "makeAppointment",
        "quotation",
        "privacy",
        "modal",
      ],
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
      react: {
        useSuspense: false,
      },
    });
};

export default configureLocalization;
