import { OPEN_MODAL, CLOSE_MODAL } from "./actions";

const initialState = {
  modal: {
    isOpen: false,
    options: {
      message: "",
      onCancel: null,
      onOk: null,
      title: "",
      okText: "",
      cancelText: "",
    },
  },
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        ...state,
        modal: {
          isOpen: true,
          options: {
            ...action.options,
          },
        },
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        modal: {
          isOpen: false,
          options: {
            message: "",
            onCancel: null,
            onOk: null,
            title: "",
            okText: "",
            cancelText: "",
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;
