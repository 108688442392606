import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Error() {
  const { t } = useTranslation("pageNotFound");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - 404 PAGE NOT FOUND!`}</title>
      </Helmet>
      <Header />
      {/* error 404 */}
      <section className="error-404 ttm-textcolor-grey">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="ttm-big-icon ttm-textcolor-skincolor">
                <i className="far fa-thumbs-down" />
              </div>
              <header className="page-header">
                <h1 className="page-title">404 {t("pageNotFound")}</h1>
              </header>
              <div className="page-content">
                {" "}
                <p>{t("pageNotFoundDetail")}</p>
              </div>
              <a
                className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor mb-15"
                href={process.env.PUBLIC_URL + "/"}
              >
                {t("backToHome")}
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* error 404 end*/}
    </div>
  );
}
