import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function OurPolicies() {
  const { t } = useTranslation("ourPolicies");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Our Policies`}</title>
        <meta
          name="description"
          content={`We are aware of the importance of quality, continuity and flawless work in IT processes.`}
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("ourPolicies")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("ourPolicies")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      {/* about-services-section */}
      <section className="ttm-row about-services-section clearfix">
        <div className="container-xl">
          <div className="row align-items-center">
            {/* row */}
            <div className="col-lg-6">
              <div className="position-relative">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper text-sm-center">
                  <img
                    className="img-fluid"
                    src="../images/policies.jpg"
                    title="Our Policies"
                    alt="Our Policies"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="res-991-mt-30">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>{t("ourPolicies")}</h5>
                    <h2 className="title">{t("motto")}</h2>
                  </div>
                  <div className="title-desc">
                    <p>{t("ourPoliciesText")}</p>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <p>{t("policy1")}</p>
                        </div>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <p>{t("policy2")}</p>
                        </div>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <p>{t("policy3")}</p>
                        </div>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <p>{t("policy4")}</p>
                        </div>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-3" />
                </div>
                <div className="row mt-25">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box iconalign-before-heading">
                      <div className="featured-content">
                        {/* featured-content */}
                        <div className="ttm-icon ttm-bgcolor-grey style1">
                          <i className="ti ti-info ttm-num" />
                        </div>
                        <div className="featured-title">
                          {/* featured-title */}
                          <p>{t("policy5")}</p>
                        </div>
                      </div>
                    </div>
                    {/* featured-icon-box */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* about-services-section end */}
    </div>
  );
}
