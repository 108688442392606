import React from "react";
import Header from "../layout/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function CompanyInfo() {
  const { t } = useTranslation("companyInfo");
  return (
    <div className="site-main">
      <Helmet>
        <title>{`Runic Bytes - Company`}</title>
        <meta
          name="description"
          content="As a company that listens to you, gives advice, and most importantly, designs together, Runic Bytes strives for happy customers and ongoing relationships. Success is measured by results, the most important thing for Runic Bytes is how their customers feel about their experience with them."
        />
      </Helmet>
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{t("companyInfo")}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a
                      title={t("homepage")}
                      href={process.env.PUBLIC_URL + "/"}
                    >
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;{t("homepage")}
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>{t("companyInfo")}</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      {/* aboutus-section */}
      <section className="ttm-row aboutus-section clearfix">
        <div className="container-xl">
          <div className="row">
            {/* row */}
            <div className="col-lg-6">
              {/* section title */}
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h5>{t("companyInfo")}</h5>
                  <h2 className="title">{t("motto1")}</h2>
                </div>
                <div className="title-desc">
                  <p>{t("companyInfo1")}</p>
                  <p>{t("companyInfo2")}</p>
                </div>
              </div>
              {/* section title end */}
              {/* row */}
              <div className="row no-gutters mt-4 mb-4">
                <div className="col-md-6 col-lg-6 col-sm-6">
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category1")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category2")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category3")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category4")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category5")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category6")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category7")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category8")}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6">
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category9")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category10")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {" "}
                        {t("category11")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category12")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category13")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category14")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category15")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category16")}
                      </span>
                    </li>
                    <li>
                      <i className="ti-check-box ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        {t("category17")}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* row END*/}
              <p className="mb-2">{t("companyInfo3")}</p>
            </div>
            <div className="col-lg-6">
              <div className="position-relative res-991-mt-30">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper text-sm-center">
                  <img
                    className="img-fluid"
                    src="../images/software.jpg"
                    title="software development"
                    alt="software development"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* aboutus-section end*/}
      {/* progress-bar-section */}
      <section className="ttm-row zero-padding-section res-991-p-0 ttm-bgcolor-white clearfix">
        <div className="container-xl">
          <div className="row no-gutters">
            <div className="col-md-12">
              <div className="ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-left-span spacing-7 position-relative z-1">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>
                <div className="layer-content">
                  <div className="row">
                    <div className="col-lg-6">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src="../images/colleagues-giving-fist-bump.jpg"
                          title="Runic Bytes"
                          alt="Runic Bytes"
                        />
                      </div>
                      {/* ttm_single_image-wrapper end */}
                      <div className="about-overlay-02">
                        <p>{t("withTeam")}</p>
                        <h3>{t("itExp")}</h3>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="res-991-mt-30">
                        {/* section title */}
                        <div className="section-title with-desc clearfix">
                          <div className="title-header">
                            <h5>{t("aboutCompany")}</h5>
                            <h2 className="title">{t("motto2")}</h2>
                          </div>
                          <div className="title-desc">
                            <p>{t("whyUsText")}</p>
                          </div>
                        </div>
                        {/* section title end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* progress-bar-section end */}
      {/* fid-section */}

      {/* fid-section end */}
    </div>
  );
}
